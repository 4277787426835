import update from 'immutability-helper'

import axios from 'axios'
// import _ from 'lodash'

import { fetch_error, secure_data, set_auth_header } from './index'

const initialState = {
  total: 0,
  response: [],
  loading: false,
  loading_create: false,
  loading_loading: false,
  current_data: {},
  action: null,
  form: null,
  processed_data: null,
  date: new Date()
}

export function setCurrentActivityUser(data) {
  return (dispatch, store) => {
    dispatch({
      type: 'SET_CURRENT_ACTIVITY_USER',
      payload: data
    })
  }
}

export function resetActivityUser(bool) {
  return (dispatch, store) => {
    dispatch({
      type: 'RESET_ACTIVITY_USER',
      payload: null
    })
  }
}

export function setFormActivityUser(bool) {
  return (dispatch, store) => {
    dispatch({
      type: 'SET_FORM_ACTIVITY_USER',
      payload: bool
    })
  }
}

export function doDeleteActivityUser(id) {
  return (dispatch, store) => {
    // console.log(id)
    const data = { _method: 'POST' }
    const options = {
      method: 'POST',
      headers: set_auth_header(store),
      url: process.env.REACT_APP_API_URL + '/activity_user/delete/' + id,
      data: secure_data(data)
    }

    dispatch({
      type: 'SET_VALIDATION',
      payload: []
    })

    dispatch({
      type: 'SET_LOADING_ACTIVITY_USER',
      payload: true
    })

    axios(options)
      .then(function (response) {
        // console.log(response.data)

        dispatch({
          type: 'SET_LOADING_ACTIVITY_USER',
          payload: false
        })

        dispatch({
          type: 'SET_MESSAGE',
          payload: response.data.message
        })

        if (response.data.success) {
          dispatch({
            type: 'SET_PROCESSED_ACTIVITY_USER',
            payload: response.data.data
          })
        }
      })
      .catch((error) => {
        fetch_error(error, dispatch)

        dispatch({
          type: 'SET_LOADING_ACTIVITY_USER',
          payload: false
        })
      })
  }
}

export function doUpdateActivityUser(data) {
  return (dispatch, store) => {
    console.log('DATA', data)

    data._method = 'POST'

    const options = {
      method: 'POST',
      headers: set_auth_header(store),
      url:
        process.env.REACT_APP_API_URL +
        '/activity_user/update/' +
        data.no_kta_anggota,
      data: secure_data(data)
    }

    dispatch({
      type: 'SET_VALIDATION',
      payload: []
    })

    dispatch({
      type: 'SET_LOADING_ACTIVITY_USER',
      payload: true
    })

    axios(options)
      .then(function (response) {
        // console.log(response.data)

        dispatch({
          type: 'SET_CURRENT_ACTIVITY_USER',
          payload: response.data.data
        })

        dispatch({
          type: 'SET_LOADING_ACTIVITY_USER',
          payload: false
        })

        dispatch({
          type: 'SET_MESSAGE',
          payload: response.data.message
        })

        if (response.data.success) {
          dispatch({
            type: 'SET_PROCESSED_ACTIVITY_USER',
            payload: response.data.data
          })

          dispatch({
            type: 'SET_FORM_ACTIVITY_USER',
            payload: false
          })
        }
      })
      .catch((error) => {
        fetch_error(error, dispatch)

        dispatch({
          type: 'SET_LOADING_ACTIVITY_USER',
          payload: false
        })
      })
  }
}

export function doCreateActivityUser(data) {
  return (dispatch, store) => {
    // console.log(data)

    data._method = 'POST'

    const options = {
      method: 'POST',
      headers: set_auth_header(store),
      url: process.env.REACT_APP_API_URL + '/activity_user/create',
      data: secure_data(data)
    }

    dispatch({
      type: 'SET_VALIDATION',
      payload: []
    })

    dispatch({
      type: 'SET_LOADING_ACTIVITY_USER',
      payload: true
    })

    axios(options)
      .then(function (response) {
        // console.log(response.data)

        dispatch({
          type: 'SET_CURRENT_ACTIVITY_USER',
          payload: response.data.data
        })

        dispatch({
          type: 'SET_LOADING_ACTIVITY_USER',
          payload: false
        })

        dispatch({
          type: 'SET_MESSAGE',
          payload: response.data.message
        })

        dispatch({
          type: 'SET_ACTION',
          payload: null
        })

        if (response.data.success) {
          dispatch({
            type: 'SET_ACTION',
            payload: 'CREATE_SUCCESS'
          })
          dispatch({
            type: 'SET_PROCESSED_ACTIVITY_USER',
            payload: response.data.data
          })

          dispatch({
            type: 'SET_FORM_ACTIVITY_USER',
            payload: false
          })
        }
      })
      .catch((error) => {
        fetch_error(error, dispatch)
        dispatch({
          type: 'SET_ACTION',
          payload: 'CREATE_FAILED'
        })

        dispatch({
          type: 'SET_LOADING_ACTIVITY_USER',
          payload: false
        })
      })
  }
}

export function getActivityUser(
  filter_provinsi = null,
  filter_kabupaten = null,
  filter_kecamatan = null,
  id,
  page = 0,
  load = 5,
  keyword = '',
  sorted = []
) {
  return (dispatch, store) => {
    let url = process.env.REACT_APP_API_URL + '/activity_user?'

    if (id) {
      url += '&id=' + id
    }

    const options = {
      data: secure_data({
        page,
        load,
        keyword,
        sorted
      }),
      method: 'POST',
      headers: set_auth_header(store),
      url
    }

    dispatch({
      type: 'SET_VALIDATION',
      payload: []
    })

    dispatch({
      type: 'SET_LOADING_ACTIVITY_USER',
      payload: true
    })

    dispatch({
      type: 'SET_ACTION',
      payload: null
    })

    axios(options)
      .then(function (response) {
        // console.log(response.data)
        dispatch({
          type: 'SET_ACTION',
          payload: 'GET_SUCCESS'
        })
        dispatch({
          type: 'SET_LOADING_ACTIVITY_USER',
          payload: false
        })
        dispatch({
          type: 'SET_ACTIVITY_USER_ALL',
          payload: response.data
        })
      })
      .catch((error) => {
        fetch_error(error, dispatch)
        dispatch({
          type: 'SET_ACTION',
          payload: 'GET_FAILED'
        })
        dispatch({
          type: 'SET_LOADING_ACTIVITY_USER',
          payload: false
        })
      })
  }
}

export function getActivityUserRead(no_kta, menu) {
  return (dispatch, store) => {
    const url = process.env.REACT_APP_API_URL + '/activity_user/' + no_kta

    const options = {
      data: secure_data({
        no_kta,
        menu
      }),
      method: 'POST',
      headers: set_auth_header(store),
      url
    }

    dispatch({
      type: 'SET_VALIDATION',
      payload: []
    })

    dispatch({
      type: 'SET_LOADING_ACTIVITY_USER',
      payload: true
    })

    dispatch({
      type: 'SET_ACTION',
      payload: null
    })

    axios(options)
      .then(function (response) {
        // console.log(response.data)
        dispatch({
          type: 'SET_ACTION',
          payload: 'GET_SUCCESS'
        })
        dispatch({
          type: 'SET_LOADING_ACTIVITY_USER',
          payload: false
        })
        dispatch({
          type: 'SET_ACTIVITY_USER',
          payload: response.data
        })
      })
      .catch((error) => {
        fetch_error(error, dispatch)
        dispatch({
          type: 'SET_ACTION',
          payload: 'GET_FAILED'
        })
        dispatch({
          type: 'SET_LOADING_ACTIVITY_USER',
          payload: false
        })
      })
  }
}

export function getActivityUserDashboard(no_kta, menu) {
  return (dispatch, store) => {
    const url =
      process.env.REACT_APP_API_URL + '/activity_user_dashboard/' + no_kta

    const options = {
      data: secure_data({
        no_kta,
        menu
      }),
      method: 'POST',
      headers: set_auth_header(store),
      url
    }

    dispatch({
      type: 'SET_VALIDATION',
      payload: []
    })

    dispatch({
      type: 'SET_LOADING_ACTIVITY_USER',
      payload: true
    })

    dispatch({
      type: 'SET_ACTION',
      payload: null
    })

    axios(options)
      .then(function (response) {
        // console.log(response.data)
        dispatch({
          type: 'SET_ACTION',
          payload: 'GET_SUCCESS'
        })
        dispatch({
          type: 'SET_LOADING_ACTIVITY_USER',
          payload: false
        })
        dispatch({
          type: 'SET_ACTIVITY_USER',
          payload: response.data
        })
      })
      .catch((error) => {
        fetch_error(error, dispatch)
        dispatch({
          type: 'SET_ACTION',
          payload: 'GET_FAILED'
        })
        dispatch({
          type: 'SET_LOADING_ACTIVITY_USER',
          payload: false
        })
      })
  }
}

export default function activity_user(state = initialState, action) {
  switch (action.type) {
    case 'SET_ACTIVITY_USER':
      return update(state, {
        response: {
          data: {
            $set: action.payload
          }
        },
        processed_data: {
          $set: null
        }
      })

    case 'SET_ACTIVITY_USER_ALL':
      return update(state, {
        response: {
          data_all: {
            $set: action.payload
          }
        },
        processed_data: {
          $set: null
        }
      })

    case 'SET_ACTION':
      return update(state, {
        action: {
          $set: action.payload
        }
      })

    case 'RESET_ACTIVITY_USER':
      return update(state, {
        current_data: {
          $set: null
        },
        processed_data: {
          $set: null
        }
      })

    case 'SET_CURRENT_ACTIVITY_USER':
      return update(state, {
        current_data: {
          $set: action.payload
        }
      })

    case 'SET_PROCESSED_ACTIVITY_USER':
      return update(state, {
        processed_data: {
          $set: action.payload
        }
      })

    case 'SET_LOADING_ACTIVITY_USER':
      return update(state, {
        loading: {
          $set: action.payload
        }
      })

    case 'SET_LOADING_PEMBAYARAN_ANGGOTA':
      return update(state, {
        loading_anggota: {
          $set: action.payload
        }
      })

    case 'SET_TOTAL_ACTIVITY_USER':
      return update(state, {
        total: {
          $set: action.payload
        }
      })

    case 'SET_FORM_ACTIVITY_USER':
      return update(state, {
        form: {
          $set: action.payload
        }
      })

    // case 'FIND_ACTIVITY_USER':
    // let find = _.find(state.response, function (o) {
    // return o.id < action.payload
    // })
    //
    // return update(state, {
    //   current_data: {
    //     $set: find
    //   }
    // })

    default:
      return state
  }
}
