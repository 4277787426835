import update from 'immutability-helper'

import axios from 'axios'
import _ from 'lodash'

import kabupaten from '../assets/kabupaten.json'

import { fetch_error, secure_data, set_auth_header } from './index'

const initialState = {
  total: 0,
  response: [],
  loading: false,
  current_data: {},
  form: null,
  processed_data: null,
  date: new Date()
}

export function setCurrentKabupaten(data) {
  return (dispatch, store) => {
    dispatch({
      type: 'SET_CURRENT_LOCATION_KABUPATEN',
      payload: data
    })
  }
}

export function resetKabupaten(bool) {
  return (dispatch, store) => {
    dispatch({
      type: 'RESET_LOCATION_KABUPATEN',
      payload: null
    })
  }
}

export function setFormKabupaten(bool) {
  return (dispatch, store) => {
    dispatch({
      type: 'SET_FORM_LOCATION_KABUPATEN',
      payload: bool
    })
  }
}

export function doDeleteKabupaten(id) {
  return (dispatch, store) => {
    // console.log(id)
    const data = { _method: 'POST' }
    const options = {
      method: 'POST',
      headers: set_auth_header(store),
      url: process.env.REACT_APP_API_URL + '/location_kabupaten/delete/' + id,
      data: secure_data(data)
    }

    dispatch({
      type: 'SET_VALIDATION',
      payload: []
    })

    dispatch({
      type: 'SET_LOADING_LOCATION_KABUPATEN',
      payload: true
    })

    axios(options)
      .then(function (response) {
        // console.log(response.data)

        dispatch({
          type: 'SET_LOADING_LOCATION_KABUPATEN',
          payload: false
        })

        dispatch({
          type: 'SET_MESSAGE',
          payload: response.data.message
        })

        if (response.data.success) {
          dispatch({
            type: 'SET_PROCESSED_LOCATION_KABUPATEN',
            payload: response.data.data
          })
        }
      })
      .catch((error) => {
        fetch_error(error, dispatch)

        dispatch({
          type: 'SET_LOADING_LOCATION_KABUPATEN',
          payload: false
        })
      })
  }
}

export function doUpdateKabupaten(data) {
  return (dispatch, store) => {
    // console.log(data)

    const id = store().location_kabupaten.current_data.id

    data._method = 'POST'

    const options = {
      method: 'POST',
      headers: set_auth_header(store),
      url: process.env.REACT_APP_API_URL + '/location_kabupaten/update/' + id,
      data: secure_data(data)
    }

    dispatch({
      type: 'SET_VALIDATION',
      payload: []
    })

    dispatch({
      type: 'SET_LOADING_LOCATION_KABUPATEN',
      payload: true
    })

    axios(options)
      .then(function (response) {
        // console.log(response.data)

        dispatch({
          type: 'SET_LOADING_LOCATION_KABUPATEN',
          payload: false
        })

        dispatch({
          type: 'SET_MESSAGE',
          payload: response.data.message
        })

        if (response.data.success) {
          dispatch({
            type: 'SET_PROCESSED_LOCATION_KABUPATEN',
            payload: response.data.data
          })

          dispatch({
            type: 'SET_FORM_LOCATION_KABUPATEN',
            payload: false
          })
        }
      })
      .catch((error) => {
        fetch_error(error, dispatch)

        dispatch({
          type: 'SET_LOADING_LOCATION_KABUPATEN',
          payload: false
        })
      })
  }
}

export function doCreateKabupaten(data) {
  return (dispatch, store) => {
    // console.log(data)

    data._method = 'POST'

    const options = {
      method: 'POST',
      headers: set_auth_header(store),
      url: process.env.REACT_APP_API_URL + '/location_kabupaten/create',
      data: secure_data(data)
    }

    dispatch({
      type: 'SET_VALIDATION',
      payload: []
    })

    dispatch({
      type: 'SET_LOADING_LOCATION_KABUPATEN',
      payload: true
    })

    axios(options)
      .then(function (response) {
        // console.log(response.data)

        dispatch({
          type: 'SET_LOADING_LOCATION_KABUPATEN',
          payload: false
        })

        dispatch({
          type: 'SET_MESSAGE',
          payload: response.data.message
        })

        if (response.data.success) {
          dispatch({
            type: 'SET_PROCESSED_LOCATION_KABUPATEN',
            payload: response.data.data
          })

          dispatch({
            type: 'SET_FORM_LOCATION_KABUPATEN',
            payload: false
          })
        }
      })
      .catch((error) => {
        fetch_error(error, dispatch)

        dispatch({
          type: 'SET_LOADING_LOCATION_KABUPATEN',
          payload: false
        })
      })
  }
}

export function getKabupaten(provinsi_id) {
  return (dispatch, store) => {
    const level_kabupaten = String(store().auth.user.level_kabupaten).split(
      ', '
    )

    const data_level = []

    for (let i = 0; i < level_kabupaten.length; i++) {
      const isi = _.find(kabupaten, (o) => {
        return parseInt(o.id) === parseInt(level_kabupaten[i])
      })

      if (isi) {
        data_level.push(isi)
      }
    }

    // console.log('data_level', data_level)

    let data_kabupaten = []
    if (_.isNumber(provinsi_id)) {
      data_kabupaten = _.filter(kabupaten, ['provinsi_id', provinsi_id])
    } else {
      data_kabupaten = kabupaten
    }

    dispatch({
      type: 'SET_LOCATION_KABUPATEN',
      payload: {
        success: true,
        data: data_kabupaten,
        data_level: data_level,
        message: ''
      }
    })
  }
}

export default function location_kabupaten(state = initialState, action) {
  switch (action.type) {
    case 'SET_LOCATION_KABUPATEN':
      return update(state, {
        response: {
          $set: action.payload
        },
        processed_data: {
          $set: null
        }
      })

    case 'RESET_LOCATION_KABUPATEN':
      return update(state, {
        current_data: {
          $set: null
        },
        processed_data: {
          $set: null
        }
      })

    case 'SET_CURRENT_LOCATION_KABUPATEN':
      return update(state, {
        current_data: {
          $set: action.payload
        }
      })

    case 'SET_PROCESSED_LOCATION_KABUPATEN':
      return update(state, {
        processed_data: {
          $set: action.payload
        }
      })

    case 'SET_LOADING_LOCATION_KABUPATEN':
      return update(state, {
        loading: {
          $set: action.payload
        }
      })

    case 'SET_TOTAL_LOCATION_KABUPATEN':
      return update(state, {
        total: {
          $set: action.payload
        }
      })

    case 'SET_FORM_LOCATION_KABUPATEN':
      return update(state, {
        form: {
          $set: action.payload
        }
      })

    // case 'FIND_LOCATION_KABUPATEN':
    // let find = _.find(state.response, function (o) {
    // return o.id < action.payload
    // })
    //
    // return update(state, {
    //   current_data: {
    //     $set: find
    //   }
    // })

    default:
      return state
  }
}
