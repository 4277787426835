import update from 'immutability-helper'

import axios from 'axios'
// import _ from 'lodash'

import { fetch_error, secure_data, set_auth_header } from './index'

const initialState = {
  total: 0,
  response: [],
  loading: false,
  current_data: {},
  form: null,
  processed_data: null,
  date: new Date()
}

export function setCurrentAnggotaKehormatan(data) {
  return (dispatch, store) => {
    dispatch({
      type: 'SET_CURRENT_ANGGOTA_KEHORMATAN',
      payload: data
    })
  }
}

export function resetAnggotaKehormatan(bool) {
  return (dispatch, store) => {
    dispatch({
      type: 'RESET_ANGGOTA_KEHORMATAN',
      payload: null
    })
  }
}

export function setFormAnggotaKehormatan(bool) {
  return (dispatch, store) => {
    dispatch({
      type: 'SET_FORM_ANGGOTA_KEHORMATAN',
      payload: bool
    })
  }
}

export function doDeleteAnggotaKehormatan(id) {
  return (dispatch, store) => {
    // console.log(id)
    const data = { _method: 'POST' }
    const options = {
      method: 'POST',
      headers: set_auth_header(store),
      url: process.env.REACT_APP_API_URL + '/anggota_kehormatan/delete/' + id,
      data: secure_data(data)
    }

    dispatch({
      type: 'SET_VALIDATION',
      payload: []
    })

    dispatch({
      type: 'SET_LOADING_ANGGOTA_KEHORMATAN',
      payload: true
    })

    axios(options)
      .then(function (response) {
        // console.log(response.data)

        dispatch({
          type: 'SET_LOADING_ANGGOTA_KEHORMATAN',
          payload: false
        })

        dispatch({
          type: 'SET_MESSAGE',
          payload: response.data.message
        })

        if (response.data.success) {
          dispatch({
            type: 'SET_PROCESSED_ANGGOTA_KEHORMATAN',
            payload: response.data.data
          })
        }
      })
      .catch((error) => {
        fetch_error(error, dispatch)

        dispatch({
          type: 'SET_LOADING_ANGGOTA_KEHORMATAN',
          payload: false
        })
      })
  }
}

// export function doDeleteAnggotaKehormatanAdmin (data) {
//   return (dispatch, store) => {
//     // console.log(data)

//     // let id = store().anggota_kehormatan.current_data.id
//     let id = data.no_kta;

//     data._method = 'POST'

//     const options = {

//       method: 'POST',
//       url: process.env.REACT_APP_API_URL + '/admin_delete/' + id,
//       data: secure_data(data)
//     }

//     dispatch({
//       type: 'SET_VALIDATION',
//       payload: []
//     })

//     dispatch({
//       type: 'SET_LOADING_ANGGOTA_KEHORMATAN',
//       payload: true
//     })

//     axios(options)
//       .then(function (response) {
//         // console.log(response.data)

//         dispatch({
//           type: 'SET_LOADING_ANGGOTA_KEHORMATAN',
//           payload: false
//         })

//         dispatch({
//           type: 'SET_MESSAGE',
//           payload: response.data.message
//         })

//         if (response.data.success) {
//           dispatch({
//             type: 'SET_PROCESSED_ANGGOTA_KEHORMATAN',
//             payload: response.data.data
//           })

//           dispatch({
//             type: 'SET_FORM_ANGGOTA_KEHORMATAN',
//             payload: false
//           })
//         }
//       })
//       .catch((error) => {
//         fetch_error(error, dispatch)

//         dispatch({
//           type: 'SET_LOADING_ANGGOTA_KEHORMATAN',
//           payload: false
//         })
//       })
//   }
// }

export function doUpdateAnggotaKehormatan(data) {
  return (dispatch, store) => {
    // console.log(data)

    const id = store().anggota_kehormatan.current_data.id
    // let id = data.anggota_kehormatan;

    data._method = 'POST'

    const options = {
      method: 'POST',
      headers: set_auth_header(store),
      url: process.env.REACT_APP_API_URL + '/anggota_kehormatan/update/' + id,
      data: secure_data(data)
    }

    dispatch({
      type: 'SET_VALIDATION',
      payload: []
    })

    dispatch({
      type: 'SET_LOADING_ANGGOTA_KEHORMATAN',
      payload: true
    })

    axios(options)
      .then(function (response) {
        // console.log(response.data)

        dispatch({
          type: 'SET_LOADING_ANGGOTA_KEHORMATAN',
          payload: false
        })

        dispatch({
          type: 'SET_MESSAGE',
          payload: response.data.message
        })

        if (response.data.success) {
          dispatch({
            type: 'SET_PROCESSED_ANGGOTA_KEHORMATAN',
            payload: response.data.data
          })

          dispatch({
            type: 'SET_FORM_ANGGOTA_KEHORMATAN',
            payload: false
          })
        }
      })
      .catch((error) => {
        fetch_error(error, dispatch)

        dispatch({
          type: 'SET_LOADING_ANGGOTA_KEHORMATAN',
          payload: false
        })
      })
  }
}

// export function doUpdateAnggotaKehormatanAdmin (data) {
//   return (dispatch, store) => {
//     // console.log(data)

//     // let id = store().anggota_kehormatan.current_data.id
//     let id = data.no_kta;

//     data._method = 'POST'

//     const options = {

//       method: 'POST',
//       url: process.env.REACT_APP_API_URL + '/admin_index/' + id,
//       data: secure_data(data)
//     }

//     dispatch({
//       type: 'SET_VALIDATION',
//       payload: []
//     })

//     dispatch({
//       type: 'SET_LOADING_ANGGOTA_KEHORMATAN',
//       payload: true
//     })

//     axios(options)
//       .then(function (response) {
//         // console.log(response.data)

//         dispatch({
//           type: 'SET_LOADING_ANGGOTA_KEHORMATAN',
//           payload: false
//         })

//         dispatch({
//           type: 'SET_MESSAGE',
//           payload: response.data.message
//         })

//         if (response.data.success) {
//           dispatch({
//             type: 'SET_PROCESSED_ANGGOTA_KEHORMATAN',
//             payload: response.data.data
//           })

//           dispatch({
//             type: 'SET_FORM_ANGGOTA_KEHORMATAN',
//             payload: false
//           })
//         }
//       })
//       .catch((error) => {
//         fetch_error(error, dispatch)

//         dispatch({
//           type: 'SET_LOADING_ANGGOTA_KEHORMATAN',
//           payload: false
//         })
//       })
//   }
// }

export function doCreateAnggotaKehormatan(data) {
  return (dispatch, store) => {
    // console.log(data)

    data._method = 'POST'

    const options = {
      method: 'POST',
      headers: set_auth_header(store),
      url: process.env.REACT_APP_API_URL + '/anggota_kehormatan/create',
      data: secure_data(data)
    }

    dispatch({
      type: 'SET_VALIDATION',
      payload: []
    })

    dispatch({
      type: 'SET_LOADING_ANGGOTA_KEHORMATAN',
      payload: true
    })

    axios(options)
      .then(function (response) {
        // console.log(response.data)

        dispatch({
          type: 'SET_LOADING_ANGGOTA_KEHORMATAN',
          payload: false
        })

        dispatch({
          type: 'SET_MESSAGE',
          payload: response.data.message
        })

        if (response.data.success) {
          dispatch({
            type: 'SET_PROCESSED_ANGGOTA_KEHORMATAN',
            payload: response.data.data
          })

          dispatch({
            type: 'SET_FORM_ANGGOTA_KEHORMATAN',
            payload: false
          })
        }
      })
      .catch((error) => {
        fetch_error(error, dispatch)

        dispatch({
          type: 'SET_LOADING_ANGGOTA_KEHORMATAN',
          payload: false
        })
      })
  }
}

export function getAnggotaKehormatan(id) {
  return (dispatch, store) => {
    let url = process.env.REACT_APP_API_URL + '/anggota_kehormatan?'

    if (id) {
      url += '&id=' + id
    }

    const options = {
      data: secure_data(),
      method: 'POST',
      headers: set_auth_header(store),
      url
    }

    dispatch({
      type: 'SET_VALIDATION',
      payload: []
    })

    dispatch({
      type: 'SET_LOADING_ANGGOTA_KEHORMATAN',
      payload: true
    })

    axios(options)
      .then(function (response) {
        // console.log(response.data)
        dispatch({
          type: 'SET_LOADING_ANGGOTA_KEHORMATAN',
          payload: false
        })
        dispatch({
          type: 'SET_ANGGOTA_KEHORMATAN',
          payload: response.data
        })
      })
      .catch((error) => {
        fetch_error(error, dispatch)
        dispatch({
          type: 'SET_LOADING_ANGGOTA_KEHORMATAN',
          payload: false
        })
      })
  }
}

export default function anggota_kehormatan(state = initialState, action) {
  switch (action.type) {
    case 'SET_ANGGOTA_KEHORMATAN':
      return update(state, {
        response: {
          $set: action.payload
        },
        processed_data: {
          $set: null
        }
      })

    case 'RESET_ANGGOTA_KEHORMATAN':
      return update(state, {
        current_data: {
          $set: null
        },
        processed_data: {
          $set: null
        }
      })

    case 'SET_CURRENT_ANGGOTA_KEHORMATAN':
      return update(state, {
        current_data: {
          $set: action.payload
        }
      })

    case 'SET_PROCESSED_ANGGOTA_KEHORMATAN':
      return update(state, {
        processed_data: {
          $set: action.payload
        }
      })

    case 'SET_LOADING_ANGGOTA_KEHORMATAN':
      return update(state, {
        loading: {
          $set: action.payload
        }
      })

    case 'SET_TOTAL_ANGGOTA_KEHORMATAN':
      return update(state, {
        total: {
          $set: action.payload
        }
      })

    case 'SET_FORM_ANGGOTA_KEHORMATAN':
      return update(state, {
        form: {
          $set: action.payload
        }
      })

    // // case 'FIND_ANGGOTA_KEHORMATAN':
    //   // let find = _.find(state.response, function (o) {
    //     // return o.id < action.payload
    //   })
    //
    //   return update(state, {
    //     current_data: {
    //       $set: find
    //     }
    //   })

    default:
      return state
  }
}
