import update from 'immutability-helper'

import axios from 'axios'
import _ from 'lodash'

import { fetch_error, secure_data, set_auth_header } from './index'

import kecamatan from '../assets/kecamatan.json'

const initialState = {
  total: 0,
  response: [],
  loading: false,
  current_data: {},
  form: null,
  processed_data: null,
  date: new Date()
}

export function setCurrentKecamatan(data) {
  return (dispatch, store) => {
    dispatch({
      type: 'SET_CURRENT_KECAMATAN',
      payload: data
    })
  }
}

export function resetKecamatan(bool) {
  return (dispatch, store) => {
    dispatch({
      type: 'RESET_KECAMATAN',
      payload: null
    })
  }
}

export function setFormKecamatan(bool) {
  return (dispatch, store) => {
    dispatch({
      type: 'SET_FORM_KECAMATAN',
      payload: bool
    })
  }
}

export function doDeleteKecamatan(id) {
  return (dispatch, store) => {
    // console.log(id)
    const data = { _method: 'POST' }
    const options = {
      method: 'POST',
      headers: set_auth_header(store),
      url: process.env.REACT_APP_API_URL + '/location_kecamatan/delete/' + id,
      data: secure_data(data)
    }

    dispatch({
      type: 'SET_VALIDATION',
      payload: []
    })

    dispatch({
      type: 'SET_LOADING_KECAMATAN',
      payload: true
    })

    axios(options)
      .then(function (response) {
        // console.log(response.data)

        dispatch({
          type: 'SET_LOADING_KECAMATAN',
          payload: false
        })

        dispatch({
          type: 'SET_MESSAGE',
          payload: response.data.message
        })

        if (response.data.success) {
          dispatch({
            type: 'SET_PROCESSED_KECAMATAN',
            payload: response.data.data
          })
        }
      })
      .catch((error) => {
        fetch_error(error, dispatch)

        dispatch({
          type: 'SET_LOADING_KECAMATAN',
          payload: false
        })
      })
  }
}

export function doUpdateKecamatan(data) {
  return (dispatch, store) => {
    // console.log(data)

    const id = store().location_kecamatan.current_data.id

    data._method = 'POST'

    const options = {
      method: 'POST',
      headers: set_auth_header(store),
      url: process.env.REACT_APP_API_URL + '/location_kecamatan/update/' + id,
      data: secure_data(data)
    }

    dispatch({
      type: 'SET_VALIDATION',
      payload: []
    })

    dispatch({
      type: 'SET_LOADING_KECAMATAN',
      payload: true
    })

    axios(options)
      .then(function (response) {
        // console.log(response.data)

        dispatch({
          type: 'SET_LOADING_KECAMATAN',
          payload: false
        })

        dispatch({
          type: 'SET_MESSAGE',
          payload: response.data.message
        })

        if (response.data.success) {
          dispatch({
            type: 'SET_PROCESSED_KECAMATAN',
            payload: response.data.data
          })

          dispatch({
            type: 'SET_FORM_KECAMATAN',
            payload: false
          })
        }
      })
      .catch((error) => {
        fetch_error(error, dispatch)

        dispatch({
          type: 'SET_LOADING_KECAMATAN',
          payload: false
        })
      })
  }
}

export function doCreateKecamatan(data) {
  return (dispatch, store) => {
    // console.log(data)

    data._method = 'POST'

    const options = {
      method: 'POST',
      headers: set_auth_header(store),
      url: process.env.REACT_APP_API_URL + '/location_kecamatan/create',
      data: secure_data(data)
    }

    dispatch({
      type: 'SET_VALIDATION',
      payload: []
    })

    dispatch({
      type: 'SET_LOADING_KECAMATAN',
      payload: true
    })

    axios(options)
      .then(function (response) {
        // console.log(response.data)

        dispatch({
          type: 'SET_LOADING_KECAMATAN',
          payload: false
        })

        dispatch({
          type: 'SET_MESSAGE',
          payload: response.data.message
        })

        if (response.data.success) {
          dispatch({
            type: 'SET_PROCESSED_KECAMATAN',
            payload: response.data.data
          })

          dispatch({
            type: 'SET_FORM_KECAMATAN',
            payload: false
          })
        }
      })
      .catch((error) => {
        fetch_error(error, dispatch)

        dispatch({
          type: 'SET_LOADING_KECAMATAN',
          payload: false
        })
      })
  }
}

export function getKecamatan(kab_id) {
  return (dispatch, store) => {
    const level_kecamatan = String(store().auth.user.level_kecamatan).split(
      ', '
    )

    const data_level = []

    for (let i = 0; i < level_kecamatan.length; i++) {
      const isi = _.find(kecamatan, (o) => {
        return parseInt(o.id) === parseInt(level_kecamatan[i])
      })

      if (isi) {
        data_level.push(isi)
      }
    }

    // console.log('data_level', data_level)

    let data_kecamatan = []
    if (_.isNumber(kab_id)) {
      data_kecamatan = _.filter(kecamatan, ['kab_id', kab_id])
    } else {
      data_kecamatan = kecamatan
    }

    dispatch({
      type: 'SET_KECAMATAN',
      payload: {
        success: true,
        data: data_kecamatan,
        data_level: data_level,
        message: ''
      }
    })
  }
}

export default function location_kecamatan(state = initialState, action) {
  switch (action.type) {
    case 'SET_KECAMATAN':
      return update(state, {
        response: {
          $set: action.payload
        },
        processed_data: {
          $set: null
        }
      })

    case 'RESET_KECAMATAN':
      return update(state, {
        current_data: {
          $set: null
        },
        processed_data: {
          $set: null
        }
      })

    case 'SET_CURRENT_KECAMATAN':
      return update(state, {
        current_data: {
          $set: action.payload
        }
      })

    case 'SET_PROCESSED_KECAMATAN':
      return update(state, {
        processed_data: {
          $set: action.payload
        }
      })

    case 'SET_LOADING_KECAMATAN':
      return update(state, {
        loading: {
          $set: action.payload
        }
      })

    case 'SET_TOTAL_KECAMATAN':
      return update(state, {
        total: {
          $set: action.payload
        }
      })

    case 'SET_FORM_KECAMATAN':
      return update(state, {
        form: {
          $set: action.payload
        }
      })

    // case 'FIND_KECAMATAN':
    // let find = _.find(state.response, function (o) {
    // return o.id < action.payload
    // })
    //
    // return update(state, {
    //   current_data: {
    //     $set: find
    //   }
    // })

    default:
      return state
  }
}
