import update from 'immutability-helper'

import axios from 'axios'
// import _ from 'lodash'

import { fetch_error, secure_data, set_auth_header } from './index'

const initialState = {
  total: 0,
  response: [],
  loading: false,
  current_data: {},
  form: null,
  processed_data: null,
  date: new Date()
}

export function setCurrentInitiatePayment(data) {
  return (dispatch, store) => {
    dispatch({
      type: 'SET_CURRENT_INITIATE_PAYMENT',
      payload: data
    })
  }
}

export function resetInitiatePayment(bool) {
  return (dispatch, store) => {
    dispatch({
      type: 'RESET_INITIATE_PAYMENT',
      payload: null
    })
  }
}

export function setFormInitiatePayment(bool) {
  return (dispatch, store) => {
    dispatch({
      type: 'SET_FORM_INITIATE_PAYMENT',
      payload: bool
    })
  }
}

export function createInitiatePayment(data) {
  return (dispatch, store) => {
    data._method = 'POST'

    const options = {
      method: 'POST',
      headers: set_auth_header(store),
      url: process.env.REACT_APP_API_URL + '/initiate_payment',
      data: secure_data(data)
    }

    dispatch({
      type: 'SET_VALIDATION',
      payload: []
    })

    dispatch({
      type: 'SET_LOADING_INITIATE_PAYMENT',
      payload: true
    })

    axios(options)
      .then(function (response) {
        // console.log(response.data)

        if (response.data.success) {
          dispatch({
            type: 'SET_ACTION_INITIATE_PAYMENT',
            payload: 'CREATE'
          })

          dispatch({
            type: 'SET_PROCESSED_INITIATE_PAYMENT',
            payload: response.data.data
          })

          dispatch({
            type: 'SET_CURRENT_INITIATE_PAYMENT',
            payload: response.data.data
          })

          dispatch({
            type: 'SET_FORM_INITIATE_PAYMENT',
            payload: false
          })
          dispatch({
            type: 'SET_MESSAGE',
            payload: response.data.message
          })
        } else {
          dispatch({
            type: 'SET_MESSAGE',
            payload: response.data.message
          })
        }

        dispatch({
          type: 'SET_LOADING_INITIATE_PAYMENT',
          payload: false
        })
      })
      .catch((error) => {
        fetch_error(error, dispatch)

        dispatch({
          type: 'SET_LOADING_INITIATE_PAYMENT',
          payload: false
        })
      })
  }
}

export default function InitiatePayment(state = initialState, action) {
  switch (action.type) {
    case 'SET_INITIATE_PAYMENT':
      return update(state, {
        response: {
          $set: action.payload
        },
        processed_data: {
          $set: null
        }
      })

    case 'RESET_INITIATE_PAYMENT':
      return update(state, {
        current_data: {
          $set: null
        },
        processed_data: {
          $set: null
        }
      })

    case 'SET_CURRENT_INITIATE_PAYMENT':
      return update(state, {
        current_data: {
          $set: action.payload
        }
      })

    case 'SET_PROCESSED_INITIATE_PAYMENT':
      return update(state, {
        processed_data: {
          $set: action.payload
        }
      })

    case 'SET_LOADING_INITIATE_PAYMENT':
      return update(state, {
        loading: {
          $set: action.payload
        }
      })

    case 'SET_TOTAL_INITIATE_PAYMENT':
      return update(state, {
        total: {
          $set: action.payload
        }
      })

    case 'SET_FORM_INITIATE_PAYMENT':
      return update(state, {
        form: {
          $set: action.payload
        }
      })

    // // case 'FIND_INITIATE_PAYMENT':
    //   // let find = _.find(state.response, function (o) {
    //     // return o.id < action.payload
    //   })
    //
    //   return update(state, {
    //     current_data: {
    //       $set: find
    //     }
    //   })

    default:
      return state
  }
}
