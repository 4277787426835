import update from 'immutability-helper'

import axios from 'axios'
// import _ from 'lodash'
import moment from 'moment'
import { fetch_error, secure_data, set_auth_header } from './index'

const initialState = {
  total: 0,
  response: [],
  loading: false,
  current_data: {},
  form: null,
  processed_data: null,
  date: new Date()
}

export function exportExcel(
  provinsi = null,
  kabupaten = null,
  kecamatan = null,
  keyword = ''
) {
  return (dispatch, store) => {
    const url = process.env.REACT_APP_API_URL + '/kta_tercetak_excel'

    const options = {
      data: secure_data({
        provinsi,
        kabupaten,
        kecamatan,
        keyword
      }),
      method: 'POST',
      headers: set_auth_header(store),
      url
    }

    dispatch({
      type: 'SET_LOADING_KTA_TERCETAK',
      payload: true
    })

    axios(options)
      .then(function (response) {
        // console.log(response)

        dispatch({
          type: 'SET_LOADING_KTA_TERCETAK',
          payload: false
        })

        // dispatch({
        //   type: 'SET_KTA_TERCETAK',
        //   payload: response.data
        // })

        if (!response.data.success) {
          dispatch({
            type: 'SET_MESSAGE',
            payload: response.data.message
          })
        }

        const url = process.env.REACT_APP_API_URL + '/kta_tercetak_excel'

        const options = {
          data: secure_data({
            provinsi,
            kabupaten,
            kecamatan,
            keyword
          }),
          method: 'POST',
          responseType: 'blob',
          headers: set_auth_header(store),
          url
        }

        dispatch({
          type: 'SET_VALIDATION',
          payload: []
        })

        dispatch({
          type: 'SET_LOADING_KTA_TERCETAK',
          payload: true
        })

        axios(options)
          .then(function (response) {
            // console.log(response)

            if (response.data.type !== 'application/json') {
              const url = window.URL.createObjectURL(new Blob([response.data]))
              const link = document.createElement('a')
              link.href = url
              link.setAttribute(
                'download',
                'PEMUDA PANCASILA - KTA DEFINITIF - ' +
                  moment().format('DDMMYYYY HHmmss') +
                  '.xlsx'
              ) // or any other extension
              document.body.appendChild(link)
              link.click()
            }

            dispatch({
              type: 'SET_LOADING_KTA_TERCETAK',
              payload: false
            })

            // dispatch({
            //   type: 'SET_KTA_TERCETAK',
            //   payload: response.data
            // })
          })
          .catch((error) => {
            fetch_error(error, dispatch)
            dispatch({
              type: 'SET_LOADING_KTA_TERCETAK',
              payload: false
            })
          })
      })
      .catch((error) => {
        fetch_error(error, dispatch)
        dispatch({
          type: 'SET_LOADING_KTA_TERCETAK',
          payload: false
        })
      })
  }

  // return (dispatch, store) => {
  //
  //   let url = process.env.REACT_APP_API_URL + '/kta_tercetak_excel';
  //
  //   const options = {
  //     data: secure_data({
  //       provinsi,
  //       kabupaten,
  //       kecamatan,
  //       keyword
  //     }),
  //     method: 'POST',
  //     responseType: 'blob',
  //     headers: set_auth_header(store),
  //     url
  //   }
  //
  //   dispatch({
  //     type: 'SET_VALIDATION',
  //     payload: []
  //   })
  //
  //   dispatch({
  //     type: 'SET_LOADING_KTA_TERCETAK',
  //     payload: true
  //   })
  //
  //   axios(options)
  //     .then(function (response) {
  //       // console.log(response)
  //
  //       if(response.data.type != "application/json"){
  //
  //         const url = window.URL.createObjectURL(new Blob([response.data]));
  //         const link = document.createElement('a');
  //         link.href = url;
  //         link.setAttribute('download', 'PEMUDA PANCASILA - KTA TERCETAK - '+moment().format("DDMMYYYY HHmmss")+'.xlsx'); //or any other extension
  //         document.body.appendChild(link);
  //         link.click();
  //
  //       }
  //
  //       dispatch({
  //         type: 'SET_LOADING_KTA_TERCETAK',
  //         payload: false
  //       })
  //
  //       dispatch({
  //         type: 'SET_KTA_TERCETAK',
  //         payload: response.data
  //       })
  //
  //     })
  //     .catch((error) => {
  //       fetch_error(error, dispatch)
  //       dispatch({
  //         type: 'SET_LOADING_KTA_TERCETAK',
  //         payload: false
  //       })
  //     })
  // }
}

export function getKtaTercetak(
  provinsi = null,
  kabupaten = null,
  kecamatan = null,
  page = 0,
  load = 5,
  keyword = '',
  sorted = []
) {
  return (dispatch, store) => {
    const url = process.env.REACT_APP_API_URL + '/kta_tercetak'
    const options = {
      data: secure_data({
        provinsi,
        kabupaten,
        kecamatan,
        page,
        load,
        keyword,
        sorted
      }),
      method: 'POST',
      headers: set_auth_header(store),
      url
    }

    dispatch({
      type: 'SET_VALIDATION',
      payload: []
    })

    dispatch({
      type: 'SET_LOADING_KTA_TERCETAK',
      payload: true
    })

    axios(options)
      .then(function (response) {
        // console.log(response.data)
        dispatch({
          type: 'SET_LOADING_KTA_TERCETAK',
          payload: false
        })
        dispatch({
          type: 'SET_KTA_TERCETAK',
          payload: response.data
        })
      })
      .catch((error) => {
        fetch_error(error, dispatch)
        dispatch({
          type: 'SET_LOADING_KTA_TERCETAK',
          payload: false
        })
      })
  }
}

export default function kta_tercetak(state = initialState, action) {
  switch (action.type) {
    case 'SET_KTA_TERCETAK':
      return update(state, {
        response: {
          $set: action.payload
        },
        processed_data: {
          $set: null
        }
      })

    case 'RESET_KTA_TERCETAK':
      return update(state, {
        current_data: {
          $set: null
        },
        processed_data: {
          $set: null
        }
      })

    case 'SET_CURRENT_KTA_TERCETAK':
      return update(state, {
        current_data: {
          $set: action.payload
        }
      })

    case 'SET_PROCESSED_KTA_TERCETAK':
      return update(state, {
        processed_data: {
          $set: action.payload
        }
      })

    case 'SET_LOADING_KTA_TERCETAK':
      return update(state, {
        loading: {
          $set: action.payload
        }
      })

    case 'SET_TOTAL_KTA_TERCETAK':
      return update(state, {
        total: {
          $set: action.payload
        }
      })

    case 'SET_FORM_KTA_TERCETAK':
      return update(state, {
        form: {
          $set: action.payload
        }
      })

    // case 'FIND_KTA_TERCETAK':
    // let find = _.find(state.response, function (o) {
    // return o.id < action.payload
    // })
    //
    // return update(state, {
    //   current_data: {
    //     $set: find
    //   }
    // })

    default:
      return state
  }
}
