import update from 'immutability-helper'

import axios from 'axios'
// import _ from 'lodash'

import { fetch_error, secure_data, set_auth_header } from './index'

export function setCurrentKeluarga(data) {
  return (dispatch, store) => {
    dispatch({
      type: 'SET_CURRENT_KELUARGA',
      payload: data
    })
  }
}

export function resetKeluarga(bool) {
  return (dispatch, store) => {
    dispatch({
      type: 'RESET_KELUARGA',
      payload: null
    })
  }
}

export function setFormKeluarga(bool) {
  return (dispatch, store) => {
    dispatch({
      type: 'SET_FORM_KELUARGA',
      payload: bool
    })
  }
}

export function doDeleteKeluarga(id) {
  return (dispatch, store) => {
    // console.log(id)
    const data = { _method: 'POST' }
    const options = {
      method: 'POST',
      headers: set_auth_header(store),
      url: process.env.REACT_APP_API_URL + '/keluarga_anggota/delete/' + id,
      data: secure_data(data)
    }

    dispatch({
      type: 'SET_VALIDATION',
      payload: []
    })

    dispatch({
      type: 'SET_FORM_KELUARGA',
      payload: true
    })

    dispatch({
      type: 'SET_LOADING_KELUARGA',
      payload: true
    })

    axios(options)
      .then(function (response) {
        // console.log(response.data)

        dispatch({
          type: 'SET_ACTION_KELUARGA',
          payload: 'DELETE'
        })

        dispatch({
          type: 'SET_LOADING_KELUARGA',
          payload: false
        })

        dispatch({
          type: 'SET_MESSAGE',
          payload: response.data.message
        })

        if (response.data.success) {
          dispatch({
            type: 'SET_PROCESSED_KELUARGA',
            payload: response.data.data
          })
        }
      })
      .catch((error) => {
        fetch_error(error, dispatch)

        dispatch({
          type: 'SET_LOADING_KELUARGA',
          payload: false
        })
      })
  }
}

export function doUpdateKeluarga(data) {
  return (dispatch, store) => {
    // console.log(data)

    const id = store().keluarga.current_data.id

    data._method = 'POST'

    const options = {
      method: 'POST',
      headers: set_auth_header(store),
      url: process.env.REACT_APP_API_URL + '/keluarga_anggota/update/' + id,
      data: secure_data(data)
    }

    dispatch({
      type: 'SET_VALIDATION',
      payload: []
    })

    dispatch({
      type: 'SET_FORM_KELUARGA',
      payload: true
    })

    dispatch({
      type: 'SET_LOADING_KELUARGA',
      payload: true
    })

    axios(options)
      .then(function (response) {
        // console.log(response.data)
        dispatch({
          type: 'SET_ACTION_KELUARGA',
          payload: 'UPDATE'
        })

        dispatch({
          type: 'SET_LOADING_KELUARGA',
          payload: false
        })

        dispatch({
          type: 'SET_MESSAGE',
          payload: response.data.message
        })

        if (response.data.success) {
          dispatch({
            type: 'SET_PROCESSED_KELUARGA',
            payload: response.data.data
          })

          dispatch({
            type: 'SET_FORM_KELUARGA',
            payload: false
          })
        }
      })
      .catch((error) => {
        fetch_error(error, dispatch)

        dispatch({
          type: 'SET_LOADING_KELUARGA',
          payload: false
        })
      })
  }
}

export function doCreateKeluarga(data) {
  return (dispatch, store) => {
    // console.log(data)

    data._method = 'POST'

    const options = {
      method: 'POST',
      headers: set_auth_header(store),
      url: process.env.REACT_APP_API_URL + '/keluarga_anggota/create',
      data: secure_data(data)
    }

    dispatch({
      type: 'SET_VALIDATION',
      payload: []
    })

    dispatch({
      type: 'SET_FORM_KELUARGA',
      payload: true
    })

    dispatch({
      type: 'SET_LOADING_KELUARGA',
      payload: true
    })

    axios(options)
      .then(function (response) {
        // console.log(response.data)
        dispatch({
          type: 'SET_ACTION_KELUARGA',
          payload: 'CREATE'
        })

        dispatch({
          type: 'SET_LOADING_KELUARGA',
          payload: false
        })

        dispatch({
          type: 'SET_MESSAGE',
          payload: response.data.message
        })

        if (response.data.success) {
          dispatch({
            type: 'SET_PROCESSED_KELUARGA',
            payload: response.data.data
          })

          dispatch({
            type: 'SET_FORM_KELUARGA',
            payload: false
          })
        }
      })
      .catch((error) => {
        fetch_error(error, dispatch)

        dispatch({
          type: 'SET_LOADING_KELUARGA',
          payload: false
        })
      })
  }
}

export function getKeluarga(no_kta_anggota) {
  return (dispatch, store) => {
    const url = process.env.REACT_APP_API_URL + '/keluarga_anggota?'

    // if (id) {
    //   url += '&id=' + id
    // }

    const options = {
      data: secure_data({ no_kta_anggota }),
      method: 'POST',
      headers: set_auth_header(store),
      url
    }

    dispatch({
      type: 'SET_LOADING_KELUARGA',
      payload: true
    })

    axios(options)
      .then(function (response) {
        // console.log(response.data)
        dispatch({
          type: 'SET_ACTION_KELUARGA',
          payload: 'GET'
        })
        dispatch({
          type: 'SET_LOADING_KELUARGA',
          payload: false
        })
        dispatch({
          type: 'SET_KELUARGA',
          payload: response.data
        })
        // dispatch({
        //   type: 'SET_VALIDATION',
        //   payload: []
        // })
      })
      .catch((error) => {
        fetch_error(error, dispatch)
        dispatch({
          type: 'SET_LOADING_KELUARGA',
          payload: false
        })
      })
  }
}

export default function keluarga(
  state = {
    total: 0,
    response: [],
    loading: false,
    current_data: {},
    form: null,
    processed_data: null,
    action: null,
    date: new Date()
  },
  action
) {
  switch (action.type) {
    case 'SET_KELUARGA':
      return update(state, {
        response: {
          $set: action.payload
        },
        processed_data: {
          $set: null
        }
      })

    case 'RESET_KELUARGA':
      return update(state, {
        current_data: {
          $set: null
        },
        processed_data: {
          $set: null
        }
      })

    case 'SET_CURRENT_KELUARGA':
      return update(state, {
        current_data: {
          $set: action.payload
        }
      })

    case 'SET_PROCESSED_KELUARGA':
      return update(state, {
        processed_data: {
          $set: action.payload
        }
      })

    case 'SET_LOADING_KELUARGA':
      return update(state, {
        loading: {
          $set: action.payload
        }
      })

    case 'SET_ACTION_KELUARGA':
      return update(state, {
        action: {
          $set: action.payload
        }
      })

    case 'SET_TOTAL_KELUARGA':
      return update(state, {
        total: {
          $set: action.payload
        }
      })

    case 'SET_FORM_KELUARGA':
      return update(state, {
        form: {
          $set: action.payload
        }
      })

    // case 'FIND_KELUARGA':
    // let find = _.find(state.response, function (o) {
    // return o.id < action.payload
    // })
    //
    // return update(state, {
    //   current_data: {
    //     $set: find
    //   }
    // })

    default:
      return state
  }
}
