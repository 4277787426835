import update from 'immutability-helper'

import axios from 'axios'
import _ from 'lodash'

import provinsi from '../assets/provinsi.json'

import { fetch_error, secure_data, set_auth_header } from './index'

const initialState = {
  total: 0,
  response: [],
  loading: false,
  current_data: {},
  form: null,
  processed_data: null,
  date: new Date()
}

export function setCurrentProvinsi(data) {
  return (dispatch, store) => {
    dispatch({
      type: 'SET_CURRENT_LOCATION_PROVINSI',
      payload: data
    })
  }
}

export function resetProvinsi(bool) {
  return (dispatch, store) => {
    dispatch({
      type: 'RESET_LOCATION_PROVINSI',
      payload: null
    })
  }
}

export function setFormProvinsi(bool) {
  return (dispatch, store) => {
    dispatch({
      type: 'SET_FORM_LOCATION_PROVINSI',
      payload: bool
    })
  }
}

export function doDeleteProvinsi(id) {
  return (dispatch, store) => {
    // console.log(id)
    const data = { _method: 'POST' }
    const options = {
      method: 'POST',
      headers: set_auth_header(store),
      url: process.env.REACT_APP_API_URL + '/location_provinsi/delete/' + id,
      data: secure_data(data)
    }

    dispatch({
      type: 'SET_VALIDATION',
      payload: []
    })

    dispatch({
      type: 'SET_LOADING_LOCATION_PROVINSI',
      payload: true
    })

    axios(options)
      .then(function (response) {
        // console.log(response.data)

        dispatch({
          type: 'SET_LOADING_LOCATION_PROVINSI',
          payload: false
        })

        dispatch({
          type: 'SET_MESSAGE',
          payload: response.data.message
        })

        if (response.data.success) {
          dispatch({
            type: 'SET_PROCESSED_LOCATION_PROVINSI',
            payload: response.data.data
          })
        }
      })
      .catch((error) => {
        fetch_error(error, dispatch)

        dispatch({
          type: 'SET_LOADING_LOCATION_PROVINSI',
          payload: false
        })
      })
  }
}

export function doUpdateProvinsi(data) {
  return (dispatch, store) => {
    // console.log(data)

    const id = store().location_provinsi.current_data.id

    data._method = 'POST'

    const options = {
      method: 'POST',
      headers: set_auth_header(store),
      url: process.env.REACT_APP_API_URL + '/location_provinsi/update/' + id,
      data: secure_data(data)
    }

    dispatch({
      type: 'SET_VALIDATION',
      payload: []
    })

    dispatch({
      type: 'SET_LOADING_LOCATION_PROVINSI',
      payload: true
    })

    axios(options)
      .then(function (response) {
        // console.log(response.data)

        dispatch({
          type: 'SET_LOADING_LOCATION_PROVINSI',
          payload: false
        })

        dispatch({
          type: 'SET_MESSAGE',
          payload: response.data.message
        })

        if (response.data.success) {
          dispatch({
            type: 'SET_PROCESSED_LOCATION_PROVINSI',
            payload: response.data.data
          })

          dispatch({
            type: 'SET_FORM_LOCATION_PROVINSI',
            payload: false
          })
        }
      })
      .catch((error) => {
        fetch_error(error, dispatch)

        dispatch({
          type: 'SET_LOADING_LOCATION_PROVINSI',
          payload: false
        })
      })
  }
}

export function doCreateProvinsi(data) {
  return (dispatch, store) => {
    // console.log(data)

    data._method = 'POST'

    const options = {
      method: 'POST',
      headers: set_auth_header(store),
      url: process.env.REACT_APP_API_URL + '/location_provinsi/create',
      data: secure_data(data)
    }

    dispatch({
      type: 'SET_VALIDATION',
      payload: []
    })

    dispatch({
      type: 'SET_LOADING_LOCATION_PROVINSI',
      payload: true
    })

    axios(options)
      .then(function (response) {
        // console.log(response.data)

        dispatch({
          type: 'SET_LOADING_LOCATION_PROVINSI',
          payload: false
        })

        dispatch({
          type: 'SET_MESSAGE',
          payload: response.data.message
        })

        if (response.data.success) {
          dispatch({
            type: 'SET_PROCESSED_LOCATION_PROVINSI',
            payload: response.data.data
          })

          dispatch({
            type: 'SET_FORM_LOCATION_PROVINSI',
            payload: false
          })
        }
      })
      .catch((error) => {
        fetch_error(error, dispatch)

        dispatch({
          type: 'SET_LOADING_LOCATION_PROVINSI',
          payload: false
        })
      })
  }
}

export function getProvinsi(id) {
  return (dispatch, store) => {
    const level_provinsi = String(store().auth.user.level_provinsi).split(', ')
    const data_level = []

    let isi = []
    for (let i = 0; i < level_provinsi.length; i++) {
      isi = _.find(provinsi, (o) => {
        return String(o.id) === String(level_provinsi[i])
      })

      if (isi) {
        data_level.push(isi)
      }
    }

    let data_provinsi = []
    if (_.isNumber(id)) {
      data_provinsi = _.filter(provinsi, ['id', id])
    } else {
      data_provinsi = provinsi
    }

    dispatch({
      type: 'SET_LOCATION_PROVINSI',
      payload: {
        success: true,
        data: data_provinsi,
        data_level: data_level,
        message: ''
      }
    })
  }
}

/*

export function getProvinsi (id) {
return (dispatch, store) => {
let url = process.env.REACT_APP_API_URL + '/location_provinsi?'

if (id) {
url += '&id=' + id
}

const options = {
data: secure_data(),
method: 'POST',
headers: set_auth_header(store),
url
}

dispatch({
type: 'SET_VALIDATION',
payload: []
})

dispatch({
type: 'SET_LOADING_LOCATION_PROVINSI',
payload: true
})

axios(options)
.then(function (response) {
// console.log(response.data)
dispatch({
type: 'SET_LOADING_LOCATION_PROVINSI',
payload: false
})
dispatch({
type: 'SET_LOCATION_PROVINSI',
payload: response.data
})
})
.catch((error) => {
fetch_error(error, dispatch)
dispatch({
type: 'SET_LOADING_LOCATION_PROVINSI',
payload: false
})
})
}
}

*/

export default function location_provinsi(state = initialState, action) {
  switch (action.type) {
    case 'SET_LOCATION_PROVINSI':
      return update(state, {
        response: {
          $set: action.payload
        },
        processed_data: {
          $set: null
        }
      })

    case 'RESET_LOCATION_PROVINSI':
      return update(state, {
        current_data: {
          $set: null
        },
        processed_data: {
          $set: null
        }
      })

    case 'SET_CURRENT_LOCATION_PROVINSI':
      return update(state, {
        current_data: {
          $set: action.payload
        }
      })

    case 'SET_PROCESSED_LOCATION_PROVINSI':
      return update(state, {
        processed_data: {
          $set: action.payload
        }
      })

    case 'SET_LOADING_LOCATION_PROVINSI':
      return update(state, {
        loading: {
          $set: action.payload
        }
      })

    case 'SET_TOTAL_LOCATION_PROVINSI':
      return update(state, {
        total: {
          $set: action.payload
        }
      })

    case 'SET_FORM_LOCATION_PROVINSI':
      return update(state, {
        form: {
          $set: action.payload
        }
      })

    // case 'FIND_LOCATION_PROVINSI':
    // let find = _.find(state.response, function (o) {
    // return o.id < action.payload
    // })
    //
    // return update(state, {
    //   current_data: {
    //     $set: find
    //   }
    // })

    default:
      return state
  }
}
