import update from 'immutability-helper'

import axios from 'axios'
// import _ from 'lodash'

import { fetch_error, secure_data, set_auth_header } from './index'

export function setCurrentQR(data) {
  return (dispatch, store) => {
    dispatch({
      type: 'SET_CURRENT_QR',
      payload: data
    })
  }
}

export function resetQR(bool) {
  return (dispatch, store) => {
    dispatch({
      type: 'RESET_QR',
      payload: null
    })
  }
}

export function setFormQR(bool) {
  return (dispatch, store) => {
    dispatch({
      type: 'SET_FORM_QR',
      payload: bool
    })
  }
}

export function getQR(qr) {
  return (dispatch, store) => {
    let url = process.env.REACT_APP_API_URL + '/qr?'

    if (qr) {
      url += '&id=' + qr
    }

    const options = {
      data: secure_data(),
      method: 'POST',
      headers: set_auth_header(store),
      url
    }

    dispatch({
      type: 'SET_VALIDATION',
      payload: []
    })

    dispatch({
      type: 'SET_LOADING_QR',
      payload: true
    })

    axios(options)
      .then(function (response) {
        // console.log(response.data)
        dispatch({
          type: 'SET_LOADING_QR',
          payload: false
        })
        dispatch({
          type: 'SET_QR',
          payload: response.data
        })
      })
      .catch((error) => {
        fetch_error(error, dispatch)
        dispatch({
          type: 'SET_LOADING_QR',
          payload: false
        })
      })
  }
}

export default function qr(
  state = {
    total: 0,
    response: [],
    loading: false,
    current_data: {},
    form: null,
    processed_data: null,
    date: new Date()
  },
  action
) {
  switch (action.type) {
    case 'SET_QR':
      return update(state, {
        response: {
          $set: action.payload
        },
        processed_data: {
          $set: null
        }
      })

    case 'RESET_QR':
      return update(state, {
        current_data: {
          $set: null
        },
        processed_data: {
          $set: null
        }
      })

    case 'SET_CURRENT_QR':
      return update(state, {
        current_data: {
          $set: action.payload
        }
      })

    case 'SET_PROCESSED_QR':
      return update(state, {
        processed_data: {
          $set: action.payload
        }
      })

    case 'SET_LOADING_QR':
      return update(state, {
        loading: {
          $set: action.payload
        }
      })

    case 'SET_TOTAL_QR':
      return update(state, {
        total: {
          $set: action.payload
        }
      })

    case 'SET_FORM_QR':
      return update(state, {
        form: {
          $set: action.payload
        }
      })

    // case 'FIND_QR':
    // let find = _.find(state.response, function (o) {
    // return o.id < action.payload
    // })
    //
    // return update(state, {
    //   current_data: {
    //     $set: find
    //   }
    // })

    default:
      return state
  }
}
