import update from 'immutability-helper'

import axios from 'axios'
// import _ from 'lodash'

import { fetch_error, secure_data, set_auth_header } from './index'

const initialState = {
  total: 0,
  response: [],
  loading: false,
  current_data: {},
  form: null,
  processed_data: null,
  date: new Date()
}

export function setCurrentAdminUsers(data) {
  return (dispatch, store) => {
    dispatch({
      type: 'SET_CURRENT_ADMIN_USERS',
      payload: data
    })
  }
}

export function resetAdminUsers(bool) {
  return (dispatch, store) => {
    dispatch({
      type: 'RESET_ADMIN_USERS',
      payload: null
    })
  }
}

export function setFormAdminUsers(bool) {
  return (dispatch, store) => {
    dispatch({
      type: 'SET_FORM_ADMIN_USERS',
      payload: bool
    })
  }
}

export function doDeleteAdminUsers(id) {
  return (dispatch, store) => {
    // console.log(id)
    const data = { _method: 'POST' }
    const options = {
      method: 'POST',
      headers: set_auth_header(store),
      url: process.env.REACT_APP_API_URL + '/admin_users/delete/' + id,
      data: secure_data(data)
    }

    dispatch({
      type: 'SET_VALIDATION',
      payload: []
    })

    dispatch({
      type: 'SET_LOADING_ADMIN_USERS',
      payload: true
    })

    axios(options)
      .then(function (response) {
        // console.log(response.data)

        dispatch({
          type: 'SET_LOADING_ADMIN_USERS',
          payload: false
        })

        dispatch({
          type: 'SET_MESSAGE',
          payload: response.data.message
        })

        if (response.data.success) {
          dispatch({
            type: 'SET_PROCESSED_ADMIN_USERS',
            payload: response.data.data
          })
        }
      })
      .catch((error) => {
        fetch_error(error, dispatch)

        dispatch({
          type: 'SET_LOADING_ADMIN_USERS',
          payload: false
        })
      })
  }
}

export function doUpdateAdminUsers(data) {
  return (dispatch, store) => {
    // console.log(data)

    const id = store().admin_users.current_data.id

    data._method = 'POST'

    const options = {
      method: 'POST',
      headers: set_auth_header(store),
      url: process.env.REACT_APP_API_URL + '/admin_users/update/' + id,
      data: secure_data(data)
    }

    dispatch({
      type: 'SET_VALIDATION',
      payload: []
    })

    dispatch({
      type: 'SET_LOADING_ADMIN_USERS',
      payload: true
    })

    axios(options)
      .then(function (response) {
        // console.log(response.data)

        dispatch({
          type: 'SET_LOADING_ADMIN_USERS',
          payload: false
        })

        dispatch({
          type: 'SET_MESSAGE',
          payload: response.data.message
        })

        if (response.data.success) {
          dispatch({
            type: 'SET_PROCESSED_ADMIN_USERS',
            payload: response.data.data
          })

          dispatch({
            type: 'SET_FORM_ADMIN_USERS',
            payload: false
          })
        }
      })
      .catch((error) => {
        fetch_error(error, dispatch)

        dispatch({
          type: 'SET_LOADING_ADMIN_USERS',
          payload: false
        })
      })
  }
}

export function doCreateAdminUsers(data) {
  return (dispatch, store) => {
    // console.log(data)

    data._method = 'POST'

    const options = {
      method: 'POST',
      headers: set_auth_header(store),
      url: process.env.REACT_APP_API_URL + '/admin_users/create',
      data: secure_data(data)
    }

    dispatch({
      type: 'SET_VALIDATION',
      payload: []
    })

    dispatch({
      type: 'SET_LOADING_ADMIN_USERS',
      payload: true
    })

    axios(options)
      .then(function (response) {
        // console.log(response.data)

        dispatch({
          type: 'SET_LOADING_ADMIN_USERS',
          payload: false
        })

        dispatch({
          type: 'SET_MESSAGE',
          payload: response.data.message
        })

        if (response.data.success) {
          dispatch({
            type: 'SET_PROCESSED_ADMIN_USERS',
            payload: response.data.data
          })

          dispatch({
            type: 'SET_FORM_ADMIN_USERS',
            payload: false
          })
        }
      })
      .catch((error) => {
        fetch_error(error, dispatch)

        dispatch({
          type: 'SET_LOADING_ADMIN_USERS',
          payload: false
        })
      })
  }
}

export function getAdminUsers(id) {
  return (dispatch, store) => {
    let url = process.env.REACT_APP_API_URL + '/admin_users?'

    if (id) {
      url += '&id=' + id
    }

    const options = {
      data: secure_data(),
      method: 'POST',
      headers: set_auth_header(store),
      url
    }

    dispatch({
      type: 'SET_VALIDATION',
      payload: []
    })

    dispatch({
      type: 'SET_LOADING_ADMIN_USERS',
      payload: true
    })

    axios(options)
      .then(function (response) {
        // console.log(response.data)
        dispatch({
          type: 'SET_LOADING_ADMIN_USERS',
          payload: false
        })
        dispatch({
          type: 'SET_ADMIN_USERS',
          payload: response.data
        })
      })
      .catch((error) => {
        fetch_error(error, dispatch)
        dispatch({
          type: 'SET_LOADING_ADMIN_USERS',
          payload: false
        })
      })
  }
}

export default function admin_users(state = initialState, action) {
  switch (action.type) {
    case 'SET_ADMIN_USERS':
      return update(state, {
        response: {
          $set: action.payload
        },
        processed_data: {
          $set: null
        }
      })

    case 'RESET_ADMIN_USERS':
      return update(state, {
        current_data: {
          $set: null
        },
        processed_data: {
          $set: null
        }
      })

    case 'SET_CURRENT_ADMIN_USERS':
      return update(state, {
        current_data: {
          $set: action.payload
        }
      })

    case 'SET_PROCESSED_ADMIN_USERS':
      return update(state, {
        processed_data: {
          $set: action.payload
        }
      })

    case 'SET_LOADING_ADMIN_USERS':
      return update(state, {
        loading: {
          $set: action.payload
        }
      })

    case 'SET_TOTAL_ADMIN_USERS':
      return update(state, {
        total: {
          $set: action.payload
        }
      })

    case 'SET_FORM_ADMIN_USERS':
      return update(state, {
        form: {
          $set: action.payload
        }
      })

    // // case 'FIND_ADMIN_USERS':
    //   // let find = _.find(state.response, function (o) {
    //     // return o.id < action.payload
    //   })
    //
    //   return update(state, {
    //     current_data: {
    //       $set: find
    //     }
    //   })

    default:
      return state
  }
}
