import update from 'immutability-helper'

import axios from 'axios'
// import _ from 'lodash'

import { fetch_error, secure_data, set_auth_header } from './index'

export function setCurrentCalonAnggota(data) {
  return (dispatch, store) => {
    dispatch({
      type: 'SET_CURRENT_CALON_ANGGOTA',
      payload: data
    })
  }
}

export function resetCalonAnggota(bool) {
  return (dispatch, store) => {
    dispatch({
      type: 'RESET_CALON_ANGGOTA',
      payload: null
    })
  }
}

export function setFormCalonAnggota(bool) {
  return (dispatch, store) => {
    dispatch({
      type: 'SET_FORM_CALON_ANGGOTA',
      payload: bool
    })
  }
}

export function doDeleteCalonAnggota(id) {
  return (dispatch, store) => {
    // console.log(id)
    const data = { _method: 'POST' }
    const options = {
      method: 'POST',
      headers: set_auth_header(store),
      url: process.env.REACT_APP_API_URL + '/calon_anggota/delete/' + id,
      data: secure_data(data)
    }

    dispatch({
      type: 'SET_VALIDATION',
      payload: []
    })

    dispatch({
      type: 'SET_LOADING_CALON_ANGGOTA',
      payload: true
    })

    axios(options)
      .then(function (response) {
        // console.log(response.data)

        dispatch({
          type: 'SET_LOADING_CALON_ANGGOTA',
          payload: false
        })

        dispatch({
          type: 'SET_MESSAGE',
          payload: response.data.message
        })

        if (response.data.success) {
          dispatch({
            type: 'SET_PROCESSED_CALON_ANGGOTA',
            payload: response.data.data
          })
        }
      })
      .catch((error) => {
        fetch_error(error, dispatch)

        dispatch({
          type: 'SET_LOADING_CALON_ANGGOTA',
          payload: false
        })
      })
  }
}

export function doDeleteAnggotaAdmin(data) {
  return (dispatch, store) => {
    // console.log(data)

    // let id = store().anggota.current_data.id
    const id = data.no_kta

    data._method = 'POST'

    const options = {
      method: 'POST',
      headers: set_auth_header(store),
      url: process.env.REACT_APP_API_URL + '/admin_delete/' + id,
      data: secure_data(data)
    }

    dispatch({
      type: 'SET_VALIDATION',
      payload: []
    })

    dispatch({
      type: 'SET_LOADING_CALON_ANGGOTA',
      payload: true
    })

    dispatch({
      type: 'SET_LOADING_FILTER_WILAYAH',
      payload: true
    })

    axios(options)
      .then(function (response) {
        // console.log(response.data)

        dispatch({
          type: 'SET_LOADING_CALON_ANGGOTA',
          payload: false
        })

        dispatch({
          type: 'SET_LOADING_FILTER_WILAYAH',
          payload: false
        })

        dispatch({
          type: 'SET_MESSAGE',
          payload: response.data.message
        })

        if (response.data.success) {
          dispatch({
            type: 'SET_PROCESSED_CALON_ANGGOTA',
            payload: response.data.data
          })

          dispatch({
            type: 'SET_FORM_CALON_ANGGOTA',
            payload: false
          })
        }
      })
      .catch((error) => {
        fetch_error(error, dispatch)

        dispatch({
          type: 'SET_LOADING_CALON_ANGGOTA',
          payload: false
        })
      })
  }
}

export function doUpdateCalonAnggota(data) {
  return (dispatch, store) => {
    // console.log(data)

    const id = data.id_anggota
    // let id = data.id;

    data._method = 'POST'

    const options = {
      method: 'POST',
      headers: set_auth_header(store),
      url: process.env.REACT_APP_API_URL + '/calon_anggota/update/' + id,
      data: secure_data(data)
    }

    dispatch({
      type: 'SET_VALIDATION',
      payload: []
    })

    dispatch({
      type: 'SET_LOADING_CALON_ANGGOTA',
      payload: true
    })

    axios(options)
      .then(function (response) {
        // console.log(response.data)

        dispatch({
          type: 'SET_LOADING_CALON_ANGGOTA',
          payload: false
        })

        // dispatch({
        //   type: 'SET_MESSAGE',
        //   payload: response.data.message
        // })

        if (response.data.success) {
          dispatch({
            type: 'SET_PROCESSED_CALON_ANGGOTA',
            payload: response.data.data
          })

          dispatch({
            type: 'SET_CURRENT_CALON_ANGGOTA',
            payload: response.data.data
          })

          dispatch({
            type: 'SET_FORM_CALON_ANGGOTA',
            payload: false
          })
        }
      })
      .catch((error) => {
        fetch_error(error, dispatch)

        dispatch({
          type: 'SET_LOADING_CALON_ANGGOTA',
          payload: false
        })
      })
  }
}

export function doUpdateAnggotaAdmin(data) {
  return (dispatch, store) => {
    // console.log(data)

    // let id = store().anggota.current_data.id
    const id = data.no_kta

    data._method = 'POST'

    const options = {
      method: 'POST',
      headers: set_auth_header(store),
      url: process.env.REACT_APP_API_URL + '/admin_index/' + id,
      data: secure_data(data)
    }

    dispatch({
      type: 'SET_VALIDATION',
      payload: []
    })

    dispatch({
      type: 'SET_LOADING_CALON_ANGGOTA',
      payload: true
    })

    axios(options)
      .then(function (response) {
        // console.log(response.data)

        dispatch({
          type: 'SET_LOADING_CALON_ANGGOTA',
          payload: false
        })

        // dispatch({
        //   type: 'SET_MESSAGE',
        //   payload: response.data.message
        // })

        if (response.data.success) {
          dispatch({
            type: 'SET_PROCESSED_CALON_ANGGOTA',
            payload: response.data.data
          })

          dispatch({
            type: 'SET_FORM_CALON_ANGGOTA',
            payload: false
          })
        }
      })
      .catch((error) => {
        fetch_error(error, dispatch)

        dispatch({
          type: 'SET_LOADING_CALON_ANGGOTA',
          payload: false
        })
      })
  }
}

export function doCreateCalonAnggota(data) {
  return (dispatch, store) => {
    // console.log(data)

    data._method = 'POST'

    const options = {
      method: 'POST',
      headers: set_auth_header(store),
      url: process.env.REACT_APP_API_URL + '/calon_anggota/create',
      data: secure_data(data)
    }

    dispatch({
      type: 'SET_VALIDATION',
      payload: []
    })

    dispatch({
      type: 'SET_LOADING_CALON_ANGGOTA',
      payload: true
    })

    axios(options)
      .then(function (response) {
        // console.log(response.data)

        dispatch({
          type: 'SET_LOADING_CALON_ANGGOTA',
          payload: false
        })

        // dispatch({
        //   type: 'SET_MESSAGE',
        //   payload: response.data.message
        // })

        if (response.data.success) {
          dispatch({
            type: 'SET_PROCESSED_CALON_ANGGOTA',
            payload: response.data.data
          })

          dispatch({
            type: 'SET_CURRENT_CALON_ANGGOTA',
            payload: response.data.data
          })

          dispatch({
            type: 'SET_FORM_CALON_ANGGOTA',
            payload: false
          })
        }
      })
      .catch((error) => {
        fetch_error(error, dispatch)

        dispatch({
          type: 'SET_LOADING_CALON_ANGGOTA',
          payload: false
        })
      })
  }
}

export function getCalonAnggota(
  filter_provinsi = null,
  filter_kabupaten = null,
  filter_kecamatan = null,
  page = 0,
  load = 5,
  keyword = '',
  sorted = [],
  email = ''
) {
  return (dispatch, store) => {
    const url = process.env.REACT_APP_API_URL + '/calon_anggota?'

    // if (id) {
    //   url += '&id=' + id
    // }

    // if (filter_provinsi) {
    //   url += '&filter_provinsi=' + filter_provinsi
    // }
    //
    // if (filter_kabupaten) {
    //   url += '&filter_kabupaten=' + filter_kabupaten
    // }
    //
    // if (filter_kecamatan) {
    //   url += '&filter_kecamatan=' + filter_kecamatan
    // }
    //
    // if (email) {
    //   url += '&email=' + email
    // }
    const options = {
      data: secure_data({
        filter_provinsi,
        filter_kabupaten,
        filter_kecamatan,
        page,
        load,
        keyword,
        sorted
      }),
      method: 'POST',
      headers: set_auth_header(store),
      url
    }

    /*
    dispatch({
      type: 'SET_VALIDATION',
      payload: []
    })
    */

    dispatch({
      type: 'SET_LOADING_CALON_ANGGOTA',
      payload: true
    })

    axios(options)
      .then(function (response) {
        // console.log(response.data)
        dispatch({
          type: 'SET_LOADING_CALON_ANGGOTA',
          payload: false
        })
        dispatch({
          type: 'SET_CALON_ANGGOTA',
          payload: response.data
        })
      })
      .catch((error) => {
        fetch_error(error, dispatch)
        dispatch({
          type: 'SET_LOADING_CALON_ANGGOTA',
          payload: false
        })
      })
  }
}

export default function calon_anggota(
  state = {
    total: 0,
    response: [],
    loading: false,
    current_data: {},
    form: null,
    processed_data: null,
    date: new Date()
  },
  action
) {
  switch (action.type) {
    case 'SET_CALON_ANGGOTA':
      return update(state, {
        response: {
          $set: action.payload
        },
        processed_data: {
          $set: null
        }
      })

    case 'RESET_CALON_ANGGOTA':
      return update(state, {
        current_data: {
          $set: null
        },
        processed_data: {
          $set: null
        }
      })

    case 'SET_CURRENT_CALON_ANGGOTA':
      return update(state, {
        current_data: {
          $set: action.payload
        }
      })

    case 'SET_PROCESSED_CALON_ANGGOTA':
      return update(state, {
        processed_data: {
          $set: action.payload
        }
      })

    case 'SET_LOADING_CALON_ANGGOTA':
      return update(state, {
        loading: {
          $set: action.payload
        }
      })

    case 'SET_TOTAL_CALON_ANGGOTA':
      return update(state, {
        total: {
          $set: action.payload
        }
      })

    case 'SET_FORM_CALON_ANGGOTA':
      return update(state, {
        form: {
          $set: action.payload
        }
      })

    // case 'FIND_CALON_ANGGOTA':
    // let find = _.find(state.response, function (o) {
    // return o.id < action.payload
    // })
    //
    // return update(state, {
    //   current_data: {
    //     $set: find
    //   }
    // })

    default:
      return state
  }
}
