import React, { Component } from 'react'
import { HashRouter, Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'

// import { renderRoutes } from 'react-router-config';
import './App.scss'

import { getQR } from './redux/qr'

import { doLogout } from './redux/auth'

const loading = () => (
  <div className='animated fadeIn text-center'>
    <i
      className='fa fa-refresh fa-spin'
      aria-hidden='true'
      style={{ color: '#ffffff', marginRight: 5 }}
    ></i>
    <span style={{ color: '#ffffff' }}>Menunggu..</span>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/Pages/Login'))
const Register = React.lazy(() => import('./views/Pages/Register'))
const Page404 = React.lazy(() => import('./views/Pages/Page404'))
const Page500 = React.lazy(() => import('./views/Pages/Page500'))
const CetakKTA = React.lazy(() => import('./views/Keanggotaan/cetakKTA'))
const CetakKTAKehormatan = React.lazy(() =>
  import('./views/Keanggotaan/CetakKTAKehormatan')
)
const HalamanPDF = React.lazy(() =>
  import('./views/Keanggotaan/KTA/HalamanPDF')
)
const HalamanPDFKehormatan = React.lazy(() =>
  import('./views/Keanggotaan/KTA/HalamanPDFKehormatan')
)
const Fullscreen = React.lazy(() => import('./views/Dashboard/Fullscreen'))
const TandaTanganFull = React.lazy(() =>
  import('./views/Master/TandaTanganFull')
)

class AutoLogout extends Component {
  constructor(props) {
    super(props)

    this.state = {
      timer: null
    }

    // Set up event listeners
    this.events = ['mousemove', 'mousedown', 'keydown', 'touchstart']
    this.resetTimerHandler = this.resetTimer.bind(this)
  }

  componentDidMount() {
    this.setupEventListeners()
    this.resetTimer()
  }

  componentWillUnmount() {
    this.removeEventListeners()
  }

  setupEventListeners() {
    this.events.forEach((event) => {
      document.addEventListener(event, this.resetTimerHandler)
    })
  }

  removeEventListeners() {
    this.events.forEach((event) => {
      document.removeEventListener(event, this.resetTimerHandler)
    })
  }

  resetTimer() {
    if (this.state.timer) {
      clearTimeout(this.state.timer)
    }

    this.setState({
      timer: setTimeout(this.props.onLogout, this.props.timeout)
    })
  }

  render() {
    return <div></div>
  }
}

class App extends Component {
  handleLogout = () => {
    this.props.doLogout()
    // Perform logout action
    console.log('Auto logout')
  }

  render() {
    return (
      <HashRouter>
        <React.Suspense fallback={loading()}>
          <Switch>
            <Route
              exact
              path='/login'
              name='Login Page'
              render={(props) => <Login {...props} />}
            />
            <Route
              exact
              path='/fullscreen'
              name='Fullscreen'
              render={(props) => <Fullscreen {...props} />}
            />
            <Route
              exact
              path='/register'
              name='Register Page'
              render={(props) => <Register {...props} />}
            />
            <Route
              exact
              path='/404'
              name='Page 404'
              render={(props) => <Page404 {...props} />}
            />
            <Route
              exact
              path='/500'
              name='Page 500'
              render={(props) => <Page500 {...props} />}
            />
            <Route
              exact
              path={'/keanggotaan/cetak_kta/' + this.props.qr.response.data}
              name='Cetak KTA'
              render={(props) => <CetakKTA {...props} />}
            />
            <Route
              exact
              path='/keanggotaan/cetak_kta_kehormatan'
              name='Cetak KTA Kehormatan'
              render={(props) => <CetakKTAKehormatan {...props} />}
            />
            <Route
              exact
              path='/keanggotaan/cv'
              name='CV'
              render={(props) => <HalamanPDF {...props} />}
            />
            <Route
              exact
              path='/keanggotaan/cv_kehormatan'
              name='CV'
              render={(props) => <HalamanPDFKehormatan {...props} />}
            />
            <Route
              exact
              path='/master/tanda_tangan_full'
              name='TandaTanganFull'
              render={(props) => <TandaTanganFull {...props} />}
            />
            <Route
              path='/'
              name='Home'
              render={(props) => <DefaultLayout {...props} />}
            />
          </Switch>
          <AutoLogout timeout={600000} onLogout={this.handleLogout} />
        </React.Suspense>
      </HashRouter>
    )
  }
}

const mapStateToProps = (state) => ({
  qr: state.qr || {},
  anggota: state.anggota || {}
})

const mapDispatchToProps = {
  getQR,
  doLogout
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
