import { combineReducers, createStore, applyMiddleware, compose } from 'redux'
import { coreReducer } from 'tcomponent'
import auth from './auth'
import heregestrasi from './heregestrasi'
import admin_users from './admin_users'
import anggota from './anggota'
import warn from './warn'
// import core from './core'
import { persistStore, persistReducer } from 'redux-persist'
import thunk from 'redux-thunk'
import { createLogger } from 'redux-logger'
import storage from 'localforage'
import hardSet from 'redux-persist/lib/stateReconciler/hardSet'
import jenis_kelamin from './jenis_kelamin'
import filter_wilayah from './filter_wilayah'
import agama from './agama'
import anggotapertarget from './anggotapertarget'
import anggotaperwilayah from './anggotaperwilayah'
import calon_anggota from './calon_anggota'
import hubungan from './hubungan'
import tingkat_pendidikan from './tingkat_pendidikan'
import pekerjaan from './pekerjaan'
import target_perwilayah from './target_perwilayah'
import pembayaran_lama from './pembayaran_lama'
import tingkat_kepengurusan from './tingkat_kepengurusan'
import bahasa from './bahasa'
import bahasa_anggota from './bahasa_anggota'
import dashboard from './dashboard'
import verifikasi_mpc from './verifikasi_mpc'
import verifikasi_pac from './verifikasi_pac'
import verifikasi_mpw from './verifikasi_mpw'
import rekonsiliasi_pembayaran from './rekonsiliasi_pembayaran'
import status_perkawinan from './status_perkawinan'
import beladiri from './beladiri'
import bantuan from './bantuan'
import activity_user from './activity_user'
import pekerjaan_sub from './pekerjaan_sub'
import pekerjaan_detail from './pekerjaan_detail'
import kepengurusan from './kepengurusan'
import kepengurusan_anggota from './kepengurusan_anggota'
import kepengurusan_detail from './kepengurusan_detail'
import kepengurusan_sk from './kepengurusan_sk'
import pengurus from './pengurus'
import location_kabupaten from './location_kabupaten'
import location_kecamatan from './location_kecamatan'
import location_provinsi from './location_provinsi'
import provinsi_baru from './provinsi_baru'
import workflow from './workflow'
import keluarga from './keluarga'
import hobi from './hobi'
import jabatan from './jabatan'
import rekrut_anggota from './rekrut_anggota'
import penghargaan_anggota from './penghargaan_anggota'
import pendidikan_anggota from './pendidikan_anggota'
import riwayat_jabatan from './riwayat_jabatan'
import riwayat_jabatan_luar from './riwayat_jabatan_luar'
import todo_mpc from './todo_mpc'
import todo_mpw from './todo_mpw'
import todo_anggota from './todo_anggota'
import tanda_tangan from './tanda_tangan'
import dpt_per_wilayah from './dpt_per_wilayah'
import dpt_per_kabupaten from './dpt_per_kabupaten'
import target_mubes_nasional from './target_mubes_nasional'
import anggota_kehormatan from './anggota_kehormatan'
import anggota_nasional from './anggota_nasional'
import anggota_kaderisasi from './anggota_kaderisasi'
import todo_dashboard from './todo_dashboard'
import qr from './qr'
import kta_tercetak from './kta_tercetak'
import anggota_verifikasi from './anggota_verifikasi'
import anggota_terhapus from './anggota_terhapus'
import rekap_anggota from './rekap_anggota'
import riwayat_login from './riwayat_login'
import pembayaran from './pembayaran'
import pembayaran_ditolak from './pembayaran_ditolak'
import rekening from './rekening'
import bank from './bank'
import pembayaran_user from './pembayaran_user'
import pembayaran_kta from './pembayaran_kta'
import rekap_pembayaran from './rekap_pembayaran'
import payment_method from './payment_method'
import initiate_payment from './initiate_payment'
import finnet_transaction from './finnet_transaction'
import membership_fee from './membership_fee'

export function secure_data(data) {
  const d = {
    ...data
    // token:store.getState().auth.token,
  }
  // console.log(d)

  return d
}

export function set_auth_header(store) {
  let Authorization = ''

  try {
    Authorization =
      store().auth.token.token_type + ' ' + store().auth.token.access_token
  } catch (e) {}

  return {
    Authorization,
    'Content-Type': 'application/json'
  }
}

export function fetch_error(error, dispatch) {
  if (error.response) {
    // console.log(error.response.data)
    // console.log(error.response.status)
    // console.log(error.response.headers)

    dispatch({
      type: 'SET_VALIDATION',
      payload: error.response.data
    })

    try {
      if (error.response.data.message) {
        dispatch({
          type: 'SET_MESSAGE',
          payload: error.response.data.message
        })
      }
    } catch (e) {}

    try {
      if (error.response.data.error === 'invalid_grant') {
        dispatch({
          type: 'SET_MESSAGE',
          payload: 'Login gagal. Email / Password salah'
        })
      }
    } catch (e) {}

    try {
      if (error.response.data.logout) {
        dispatch({
          type: 'DO_LOGOUT',
          payload: true
        })
      }
    } catch (e) {}
  } else if (error.request) {
    // console.log(error.request)

    dispatch({
      type: 'SET_MESSAGE',
      payload: 'Terjadi kesalahan, silahkan dicoba kembali'
    })
  } else {
    // console.log('Error', error.message)
  }

  // // console.log(error.config);
}

const log = createLogger({ diff: false, collapsed: true })

const persistConfig = {
  key: 'pemuda_pancasila',
  storage,
  stateReconciler: hardSet
}

const rootReducer = combineReducers({
  auth,
  warn,
  workflow,
  heregestrasi,
  admin_users,
  anggota,
  jabatan,
  rekrut_anggota,
  jenis_kelamin,
  filter_wilayah,
  agama,
  anggotapertarget,
  anggotaperwilayah,
  calon_anggota,
  hubungan,
  keluarga,
  tingkat_pendidikan,
  pekerjaan,
  target_perwilayah,
  pembayaran_lama,
  tingkat_kepengurusan,
  bahasa,
  bahasa_anggota,
  dashboard,
  pendidikan_anggota,
  penghargaan_anggota,
  riwayat_jabatan,
  riwayat_jabatan_luar,
  location_provinsi,
  location_kabupaten,
  location_kecamatan,
  verifikasi_mpc,
  verifikasi_mpw,
  verifikasi_pac,
  rekonsiliasi_pembayaran,
  status_perkawinan,
  beladiri,
  bantuan,
  activity_user,
  pekerjaan_sub,
  pekerjaan_detail,
  kepengurusan,
  kepengurusan_anggota,
  kepengurusan_detail,
  kepengurusan_sk,
  pengurus,
  provinsi_baru,
  hobi,
  todo_mpc,
  todo_mpw,
  todo_anggota,
  tanda_tangan,
  dpt_per_wilayah,
  dpt_per_kabupaten,
  anggota_kehormatan,
  todo_dashboard,
  qr,
  target_mubes_nasional,
  anggota_nasional,
  anggota_kaderisasi,
  kta_tercetak,
  anggota_verifikasi,
  anggota_terhapus,
  core: coreReducer,
  rekap_anggota,
  riwayat_login,
  pembayaran,
  pembayaran_ditolak,
  pembayaran_user,
  pembayaran_kta,
  rekap_pembayaran,
  payment_method,
  initiate_payment,
  finnet_transaction,
  membership_fee,
  rekening,
  bank
})

let middleware = [thunk]

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  middleware = [thunk, log]
}

const enhancers = []

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = createStore(
  persistedReducer,
  undefined,
  compose(applyMiddleware(...middleware), ...enhancers)
)

const persistor = persistStore(store)

export { store, persistor }
