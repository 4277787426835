import update from 'immutability-helper'

import axios from 'axios'

import { fetch_error, secure_data, set_auth_header } from './index'

export function doForgotPassword(email, password) {
  return (dispatch, store) => {
    const data = { email, password }

    const options = {
      method: 'POST',
      // headers: set_auth_header(store),
      data: secure_data(data),
      url: process.env.REACT_APP_API_URL + '/forgot_password'
    }

    dispatch({
      type: 'RESET_VALIDATION_MESSAGE',
      payload: null
    })

    dispatch({
      type: 'SET_LOADING',
      payload: true
    })

    axios(options)
      .then(function (response) {
        dispatch({
          type: 'DO_FORGOT_PASSWORD',
          payload: response.data
        })

        if (response.data.message) {
          dispatch({
            type: 'SET_MESSAGE',
            payload: response.data.message
          })
        }
      })
      .catch((error) => {
        fetch_error(error, dispatch)

        dispatch({
          type: 'SET_LOADING',
          payload: false
        })
      })
  }
}

export function doLogin(email, password) {
  return (dispatch, store) => {
    const data = { email, password }

    const data_oauth = {
      grant_type: 'password',
      client_id: process.env.REACT_APP_API_CLIENT_ID,
      client_secret: process.env.REACT_APP_API_CLIENT_SECRET,
      username: email,
      password,
      scope: ''
    }

    const options_oauth = {
      method: 'POST',
      data: secure_data(data_oauth),
      url: process.env.REACT_APP_API_URL + '/oauth/token'
    }

    const options = {
      method: 'POST',
      data: secure_data(data),
      url: process.env.REACT_APP_API_URL + '/login'
    }

    dispatch({
      type: 'RESET_VALIDATION_MESSAGE',
      payload: null
    })

    axios(options_oauth)
      .then((response_oauth) => {
        if (response_oauth.data.access_token) {
          axios(options)
            .then((response) => {
              dispatch({
                type: 'DO_LOGIN',
                payload: response.data
              })
              dispatch({
                type: 'OAUTH_LOGIN',
                payload: response_oauth.data
              })
            })
            .catch((error) => fetch_error(error, dispatch))
        }
      })
      .catch((error) => fetch_error(error, dispatch))
  }
}

export function doLogout() {
  return (dispatch, store) => {
    const options = {
      method: 'POST',
      headers: set_auth_header(store),
      url: process.env.REACT_APP_API_URL + '/logout?'
    }

    dispatch({
      type: 'RESET_VALIDATION_MESSAGE',
      payload: null
    })

    dispatch({
      type: 'SET_WARNING',
      payload: false
    })

    axios(options)
      .then(function (response) {
        dispatch({
          type: 'DO_LOGOUT',
          payload: response.data
        })
      })
      .catch((error) => {
        fetch_error(error, dispatch)
        dispatch({
          type: 'DO_LOGOUT',
          payload: null
        })
      })
  }
}

export function doChangePassword(
  email,
  old_password,
  password,
  password_confirmation
) {
  return (dispatch, store) => {
    const options = {
      method: 'POST',
      headers: set_auth_header(store),
      url: process.env.REACT_APP_API_URL + '/change_password',
      data: secure_data({
        email,
        old_password,
        password,
        password_confirmation
      })
    }

    dispatch({
      type: 'RESET_VALIDATION_MESSAGE',
      payload: null
    })

    axios(options)
      .then((response) => {
        dispatch({
          type: 'SET_MESSAGE',
          payload: response.data.message
        })

        if (response.data.success) {
          dispatch({
            type: 'DO_LOGOUT',
            payload: response.data
          })
        }
      })
      .catch((error) => {
        fetch_error(error, dispatch)
      })
  }
}

export default function auth(
  state = {
    user: {},
    token: '',
    forgot_password: {}
  },
  action
) {
  switch (action.type) {
    case 'DO_LOGIN':
      return update(state, {
        user: {
          $set: action.payload.user
        }
      })
    case 'OAUTH_LOGIN':
      return update(state, {
        token: {
          $set: action.payload
        }
      })
    case 'DO_FORGOT_PASSWORD':
      return update(state, {
        forgot_password: {
          $set: action.payload
        }
      })
    case 'DO_LOGOUT':
      return update(state, {
        user: {
          $set: {}
        },
        token: {
          $set: null
        }
      })
    default:
      return state
  }
}

export function getHeader(no_kta) {
  return (dispatch, store) => {
    let url = process.env.REACT_APP_API_URL + '/header?'

    if (no_kta) {
      url += '&no_kta=' + no_kta
    }

    const options = {
      data: secure_data(),
      headers: set_auth_header(store),
      method: 'POST',
      url
    }

    dispatch({
      type: 'SET_VALIDATION',
      payload: []
    })

    dispatch({
      type: 'SET_LOADING_HEADER',
      payload: true
    })

    axios(options)
      .then(function (response) {
        // console.log(response.data)
        dispatch({
          type: 'SET_LOADING_HEADER',
          payload: false
        })
        dispatch({
          type: 'SET_HEADER',
          payload: response.data
        })
      })
      .catch((error) => {
        fetch_error(error, dispatch)
        dispatch({
          type: 'SET_LOADING_HEADER',
          payload: false
        })
      })
  }
}
