import update from 'immutability-helper'

import axios from 'axios'
// import _ from 'lodash'

import { fetch_error, secure_data, set_auth_header } from './index'

export function setCurrentFilterWilayah(data) {
  return (dispatch, store) => {
    dispatch({
      type: 'SET_CURRENT_FILTER_WILAYAH',
      payload: data
    })
  }
}

export function resetFilterWilayah(bool) {
  return (dispatch, store) => {
    dispatch({
      type: 'RESET_FILTER_WILAYAH',
      payload: null
    })
  }
}

export function setFormFilterWilayah(bool) {
  return (dispatch, store) => {
    dispatch({
      type: 'SET_FORM_FILTER_WILAYAH',
      payload: bool
    })
  }
}

export function doDeleteFilterWilayah(id) {
  return (dispatch, store) => {
    // console.log(id)
    const data = { _method: 'POST' }
    const options = {
      method: 'POST',
      headers: set_auth_header(store),
      url: process.env.REACT_APP_API_URL + '/anggota/delete/' + id,
      data: secure_data(data)
    }

    dispatch({
      type: 'SET_VALIDATION',
      payload: []
    })

    dispatch({
      type: 'SET_LOADING_FILTER_WILAYAH',
      payload: true
    })

    axios(options)
      .then(function (response) {
        // console.log(response.data)

        dispatch({
          type: 'SET_LOADING_FILTER_WILAYAH',
          payload: false
        })

        dispatch({
          type: 'SET_MESSAGE',
          payload: response.data.message
        })

        if (response.data.success) {
          dispatch({
            type: 'SET_PROCESSED_FILTER_WILAYAH',
            payload: response.data.data
          })
        }
      })
      .catch((error) => {
        fetch_error(error, dispatch)

        dispatch({
          type: 'SET_LOADING_FILTER_WILAYAH',
          payload: false
        })
      })
  }
}

export function doDeleteAnggotaAdmin(data) {
  return (dispatch, store) => {
    // console.log(data)

    // let id = store().anggota.current_data.id
    const id = data.no_kta

    data._method = 'POST'

    const options = {
      method: 'POST',
      headers: set_auth_header(store),
      url: process.env.REACT_APP_API_URL + '/admin_delete/' + id,
      data: secure_data(data)
    }

    dispatch({
      type: 'SET_VALIDATION',
      payload: []
    })

    dispatch({
      type: 'SET_LOADING_FILTER_WILAYAH',
      payload: true
    })

    axios(options)
      .then(function (response) {
        // console.log(response.data)

        dispatch({
          type: 'SET_LOADING_FILTER_WILAYAH',
          payload: false
        })

        dispatch({
          type: 'SET_MESSAGE',
          payload: response.data.message
        })

        if (response.data.success) {
          dispatch({
            type: 'SET_PROCESSED_FILTER_WILAYAH',
            payload: response.data.data
          })

          dispatch({
            type: 'SET_FORM_FILTER_WILAYAH',
            payload: false
          })
        }
      })
      .catch((error) => {
        fetch_error(error, dispatch)

        dispatch({
          type: 'SET_LOADING_FILTER_WILAYAH',
          payload: false
        })
      })
  }
}

export function doUpdateFilterWilayah(data) {
  return (dispatch, store) => {
    // console.log(data)

    const id = store().anggota.current_data.id
    // let id = data.id;

    data._method = 'POST'

    const options = {
      method: 'POST',
      headers: set_auth_header(store),
      url: process.env.REACT_APP_API_URL + '/anggota/update/' + id,
      data: secure_data(data)
    }

    dispatch({
      type: 'SET_VALIDATION',
      payload: []
    })

    dispatch({
      type: 'SET_LOADING_FILTER_WILAYAH',
      payload: true
    })

    axios(options)
      .then(function (response) {
        // console.log(response.data)

        dispatch({
          type: 'SET_LOADING_FILTER_WILAYAH',
          payload: false
        })

        dispatch({
          type: 'SET_MESSAGE',
          payload: response.data.message
        })

        if (response.data.success) {
          dispatch({
            type: 'SET_PROCESSED_FILTER_WILAYAH',
            payload: response.data.data
          })

          dispatch({
            type: 'SET_FORM_FILTER_WILAYAH',
            payload: false
          })
        }
      })
      .catch((error) => {
        fetch_error(error, dispatch)

        dispatch({
          type: 'SET_LOADING_FILTER_WILAYAH',
          payload: false
        })
      })
  }
}

export function doUpdateAnggotaAdmin(data) {
  return (dispatch, store) => {
    // console.log(data)

    // let id = store().anggota.current_data.id
    const id = data.no_kta

    data._method = 'POST'

    const options = {
      method: 'POST',
      headers: set_auth_header(store),
      url: process.env.REACT_APP_API_URL + '/admin_index/' + id,
      data: secure_data(data)
    }

    dispatch({
      type: 'SET_VALIDATION',
      payload: []
    })

    dispatch({
      type: 'SET_LOADING_FILTER_WILAYAH',
      payload: true
    })

    axios(options)
      .then(function (response) {
        // console.log(response.data)

        dispatch({
          type: 'SET_LOADING_FILTER_WILAYAH',
          payload: false
        })

        dispatch({
          type: 'SET_MESSAGE',
          payload: response.data.message
        })

        if (response.data.success) {
          dispatch({
            type: 'SET_PROCESSED_FILTER_WILAYAH',
            payload: response.data.data
          })

          dispatch({
            type: 'SET_FORM_FILTER_WILAYAH',
            payload: false
          })
        }
      })
      .catch((error) => {
        fetch_error(error, dispatch)

        dispatch({
          type: 'SET_LOADING_FILTER_WILAYAH',
          payload: false
        })
      })
  }
}

export function doCreateFilterWilayah(data) {
  return (dispatch, store) => {
    // console.log(data)

    data._method = 'POST'

    const options = {
      method: 'POST',
      headers: set_auth_header(store),
      url: process.env.REACT_APP_API_URL + '/anggota/create',
      data: secure_data(data)
    }

    dispatch({
      type: 'SET_VALIDATION',
      payload: []
    })

    dispatch({
      type: 'SET_LOADING_FILTER_WILAYAH',
      payload: true
    })

    axios(options)
      .then(function (response) {
        // console.log(response.data)

        dispatch({
          type: 'SET_LOADING_FILTER_WILAYAH',
          payload: false
        })

        dispatch({
          type: 'SET_MESSAGE',
          payload: response.data.message
        })

        if (response.data.success) {
          dispatch({
            type: 'SET_PROCESSED_FILTER_WILAYAH',
            payload: response.data.data
          })

          dispatch({
            type: 'SET_CURRENT_FILTER_WILAYAH',
            payload: response.data.data
          })

          dispatch({
            type: 'SET_FORM_FILTER_WILAYAH',
            payload: false
          })
        }
      })
      .catch((error) => {
        fetch_error(error, dispatch)

        dispatch({
          type: 'SET_LOADING_FILTER_WILAYAH',
          payload: false
        })
      })
  }
}

export function getFilterWilayah(
  menu,
  filter_provinsi,
  filter_kabupaten,
  filter_kecamatan,
  page = 0,
  load = 5,
  keyword = '',
  sorted = [],
  email = ''
) {
  return (dispatch, store) => {
    let url = process.env.REACT_APP_API_URL + '/filter_wilayah?'

    if (filter_provinsi) {
      url += '&filter_provinsi=' + filter_provinsi
    }

    if (filter_kabupaten) {
      url += '&filter_kabupaten=' + filter_kabupaten
    }

    if (filter_kecamatan) {
      url += '&filter_kecamatan=' + filter_kecamatan
    }
    const options = {
      data: secure_data({
        menu,
        filter_provinsi,
        filter_kabupaten,
        filter_kecamatan,
        page,
        load,
        keyword,
        sorted
      }),
      method: 'POST',
      headers: set_auth_header(store),
      url
    }

    dispatch({
      type: 'SET_VALIDATION',
      payload: []
    })

    dispatch({
      type: 'SET_LOADING_FILTER_WILAYAH',
      payload: true
    })

    axios(options)
      .then(function (response) {
        // console.log(response.data)
        dispatch({
          type: 'SET_LOADING_FILTER_WILAYAH',
          payload: false
        })
        dispatch({
          type: 'SET_FILTER_WILAYAH',
          payload: response.data
        })
      })
      .catch((error) => {
        fetch_error(error, dispatch)
        dispatch({
          type: 'SET_LOADING_FILTER_WILAYAH',
          payload: false
        })
      })
  }
}

export default function filter_wilayah(
  state = {
    total: 0,
    response: [],
    loading: false,
    current_data: {},
    form: null,
    processed_data: null,
    date: new Date()
  },
  action
) {
  switch (action.type) {
    case 'SET_FILTER_WILAYAH':
      return update(state, {
        response: {
          $set: action.payload
        },
        processed_data: {
          $set: null
        }
      })

    case 'RESET_FILTER_WILAYAH':
      return update(state, {
        current_data: {
          $set: null
        },
        processed_data: {
          $set: null
        }
      })

    case 'SET_CURRENT_FILTER_WILAYAH':
      return update(state, {
        current_data: {
          $set: action.payload
        }
      })

    case 'SET_PROCESSED_FILTER_WILAYAH':
      return update(state, {
        processed_data: {
          $set: action.payload
        }
      })

    case 'SET_LOADING_FILTER_WILAYAH':
      return update(state, {
        loading: {
          $set: action.payload
        }
      })

    case 'SET_TOTAL_FILTER_WILAYAH':
      return update(state, {
        total: {
          $set: action.payload
        }
      })

    case 'SET_FORM_FILTER_WILAYAH':
      return update(state, {
        form: {
          $set: action.payload
        }
      })

    // case 'FIND_FILTER_WILAYAH':
    // let find = _.find(state.response, function (o) {
    // return o.id < action.payload
    // })
    //
    // return update(state, {
    //   current_data: {
    //     $set: find
    //   }
    // })

    default:
      return state
  }
}
