import update from 'immutability-helper'

import axios from 'axios'
// import _ from 'lodash'

import { fetch_error, secure_data, set_auth_header } from './index'

const initialState = {
  total: 0,
  response: [],
  loading: false,
  loading_valid: false,
  current_data: {},
  action: null,
  form: null,
  processed_data: null,
  date: new Date()
}

export function setCurrentPembayaranLama(data) {
  return (dispatch, store) => {
    dispatch({
      type: 'SET_CURRENT_PEMBAYARAN_LAMA',
      payload: data
    })
  }
}

export function resetPembayaranLama(bool) {
  return (dispatch, store) => {
    dispatch({
      type: 'RESET_PEMBAYARAN_LAMA',
      payload: null
    })
  }
}

export function setFormPembayaranLama(bool) {
  return (dispatch, store) => {
    dispatch({
      type: 'SET_FORM_PEMBAYARAN_LAMA',
      payload: bool
    })
  }
}

export function doValidPembayaranLama(data) {
  return (dispatch, store) => {
    // console.log(data)

    const options = {
      method: 'POST',
      headers: set_auth_header(store),
      url: process.env.REACT_APP_API_URL + '/approve_pembayaran_lama/',
      data: secure_data(data)
    }

    dispatch({
      type: 'SET_VALIDATION',
      payload: []
    })

    dispatch({
      type: 'SET_LOADING_PEMBAYARAN_VALID',
      payload: true
    })

    axios(options)
      .then(function (response) {
        // console.log(response.data)

        dispatch({
          type: 'SET_LOADING_PEMBAYARAN_VALID',
          payload: false
        })

        dispatch({
          type: 'SET_MESSAGE',
          payload: response.data.message
        })

        if (response.data.success) {
          dispatch({
            type: 'SET_PROCESSED_PEMBAYARAN',
            payload: response.data.data
          })

          dispatch({
            type: 'SET_FORM_PEMBAYARAN',
            payload: false
          })
        }
      })
      .catch((error) => {
        fetch_error(error, dispatch)

        dispatch({
          type: 'SET_LOADING_PEMBAYARAN_VALID',
          payload: false
        })
      })
  }
}

export function getPembayaranLama(
  filter_provinsi = null,
  filter_kabupaten = null,
  filter_kecamatan = null,
  page = 0,
  load = 5,
  keyword = '',
  sorted = []
) {
  return (dispatch, store) => {
    const url = process.env.REACT_APP_API_URL + '/pembayaran_lama?'

    const options = {
      data: secure_data({
        page,
        load,
        keyword,
        sorted,
        filter_provinsi,
        filter_kabupaten,
        filter_kecamatan
      }),
      method: 'POST',
      headers: set_auth_header(store),
      url
    }

    dispatch({
      type: 'SET_VALIDATION',
      payload: []
    })

    dispatch({
      type: 'SET_LOADING_PEMBAYARAN_LAMA',
      payload: true
    })

    dispatch({
      type: 'SET_ACTION',
      payload: null
    })

    axios(options)
      .then(function (response) {
        // console.log(response.data)
        dispatch({
          type: 'SET_ACTION',
          payload: 'GET_SUCCESS'
        })
        dispatch({
          type: 'SET_LOADING_PEMBAYARAN_LAMA',
          payload: false
        })
        dispatch({
          type: 'SET_PEMBAYARAN_LAMA',
          payload: response.data
        })
      })
      .catch((error) => {
        fetch_error(error, dispatch)
        dispatch({
          type: 'SET_ACTION',
          payload: 'GET_FAILED'
        })
        dispatch({
          type: 'SET_LOADING_PEMBAYARAN_LAMA',
          payload: false
        })
      })
  }
}

export default function pembayaran_lama(state = initialState, action) {
  switch (action.type) {
    case 'SET_PEMBAYARAN_LAMA':
      return update(state, {
        response: {
          data: {
            $set: action.payload
          }
        },
        processed_data: {
          $set: null
        }
      })

    case 'SET_ACTION':
      return update(state, {
        action: {
          $set: action.payload
        }
      })

    case 'RESET_PEMBAYARAN_LAMA':
      return update(state, {
        current_data: {
          $set: null
        },
        processed_data: {
          $set: null
        }
      })

    case 'SET_CURRENT_PEMBAYARAN_LAMA':
      return update(state, {
        current_data: {
          $set: action.payload
        }
      })

    case 'SET_PROCESSED_PEMBAYARAN_LAMA':
      return update(state, {
        processed_data: {
          $set: action.payload
        }
      })

    case 'SET_LOADING_PEMBAYARAN_LAMA':
      return update(state, {
        loading: {
          $set: action.payload
        }
      })

    case 'SET_LOADING_PEMBAYARAN_VALID':
      return update(state, {
        loading_valid: {
          $set: action.payload
        }
      })

    case 'SET_LOADING_PEMBAYARAN_ANGGOTA':
      return update(state, {
        loading_anggota: {
          $set: action.payload
        }
      })

    case 'SET_TOTAL_PEMBAYARAN_LAMA':
      return update(state, {
        total: {
          $set: action.payload
        }
      })

    case 'SET_FORM_PEMBAYARAN_LAMA':
      return update(state, {
        form: {
          $set: action.payload
        }
      })

    // case 'FIND_PEMBAYARAN_LAMA':
    // let find = _.find(state.response, function (o) {
    // return o.id < action.payload
    // })
    //
    // return update(state, {
    //   current_data: {
    //     $set: find
    //   }
    // })

    default:
      return state
  }
}
