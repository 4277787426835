import update from 'immutability-helper'

import axios from 'axios'
// import _ from 'lodash'

import { fetch_error, secure_data, set_auth_header } from './index'

const initialState = {
  total: 0,
  response: [],
  loading: false,
  current_data: {},
  form: null,
  processed_data: null,
  date: new Date()
}

export function setCurrentRekapPembayaran(data) {
  return (dispatch, store) => {
    dispatch({
      type: 'SET_CURRENT_REKAP_PEMBAYARAN',
      payload: data
    })
  }
}

export function resetRekapPembayaran(bool) {
  return (dispatch, store) => {
    dispatch({
      type: 'RESET_REKAP_PEMBAYARAN',
      payload: null
    })
  }
}

export function setFormRekapPembayaran(bool) {
  return (dispatch, store) => {
    dispatch({
      type: 'SET_FORM_REKAP_PEMBAYARAN',
      payload: bool
    })
  }
}

export function getRekapPembayaran(filter_input, exp) {
  return (dispatch, store) => {
    const url = process.env.REACT_APP_API_URL + '/rekap_pembayaran?'

    // if (id) {
    //   url += '&id=' + id
    // }

    if (filter_input === 'initial') {
      dispatch({
        type: 'SET_LOADING_REKAP_PEMBAYARAN',
        payload: false
      })

      dispatch({
        type: 'SET_REKAP_PEMBAYARAN',
        payload: []
      })
    } else {
      const options = {
        data: secure_data({ filter_input, exp }),
        method: 'POST',
        headers: set_auth_header(store),
        url
      }

      dispatch({
        type: 'SET_LOADING_REKAP_PEMBAYARAN',
        payload: true
      })

      axios(options)
        .then(function (response) {
          // console.log(response.data)
          dispatch({
            type: 'SET_LOADING_REKAP_PEMBAYARAN',
            payload: false
          })

          if (response.data.success) {
            dispatch({
              type: 'SET_REKAP_PEMBAYARAN',
              payload: response.data
            })
          } else {
            dispatch({
              type: 'SET_MESSAGE',
              payload: response.data.message
            })
          }
        })
        .catch((error) => {
          fetch_error(error, dispatch)
          dispatch({
            type: 'SET_LOADING_REKAP_PEMBAYARAN',
            payload: false
          })
        })
    }
  }
}

export default function rekap_pembayaran(state = initialState, action) {
  switch (action.type) {
    case 'SET_REKAP_PEMBAYARAN':
      return update(state, {
        response: {
          $set: action.payload
        },
        processed_data: {
          $set: null
        }
      })

    case 'RESET_REKAP_PEMBAYARAN':
      return update(state, {
        current_data: {
          $set: null
        },
        processed_data: {
          $set: null
        }
      })

    case 'SET_CURRENT_REKAP_PEMBAYARAN':
      return update(state, {
        current_data: {
          $set: action.payload
        }
      })

    case 'SET_PROCESSED_REKAP_PEMBAYARAN':
      return update(state, {
        processed_data: {
          $set: action.payload
        }
      })

    case 'SET_LOADING_REKAP_PEMBAYARAN':
      return update(state, {
        loading: {
          $set: action.payload
        }
      })

    case 'SET_TOTAL_REKAP_PEMBAYARAN':
      return update(state, {
        total: {
          $set: action.payload
        }
      })

    case 'SET_FORM_REKAP_PEMBAYARAN':
      return update(state, {
        form: {
          $set: action.payload
        }
      })

    // // case 'FIND_REKAP_PEMBAYARAN':
    //   // let find = _.find(state.response, function (o) {
    //     // return o.id < action.payload
    //   })

    //   return update(state, {
    //     current_data: {
    //       $set: find
    //     }
    //   })

    default:
      return state
  }
}
