import update from 'immutability-helper'

import axios from 'axios'
// import _ from 'lodash'

import { fetch_error, secure_data, set_auth_header } from './index'

const initialState = {
  total: 0,
  response: [],
  loading: false,
  loading_create: false,
  loading_loading: false,
  current_data: {},
  action: null,
  form: null,
  processed_data: null,
  date: new Date()
}

export function setCurrentPembayaran(data) {
  return (dispatch, store) => {
    dispatch({
      type: 'SET_CURRENT_PEMBAYARAN',
      payload: data
    })
  }
}

export function resetPembayaran(bool) {
  return (dispatch, store) => {
    dispatch({
      type: 'RESET_PEMBAYARAN',
      payload: null
    })
  }
}

export function setFormPembayaran(bool) {
  return (dispatch, store) => {
    dispatch({
      type: 'SET_FORM_PEMBAYARAN',
      payload: bool
    })
  }
}

export function doDeletePembayaran(id) {
  return (dispatch, store) => {
    // console.log(id)
    const data = { _method: 'POST' }
    const options = {
      method: 'POST',
      headers: set_auth_header(store),
      url: process.env.REACT_APP_API_URL + '/pembayaran/delete/' + id,
      data: secure_data(data)
    }

    dispatch({
      type: 'SET_VALIDATION',
      payload: []
    })

    dispatch({
      type: 'SET_LOADING_PEMBAYARAN',
      payload: true
    })

    axios(options)
      .then(function (response) {
        // console.log(response.data)

        dispatch({
          type: 'SET_LOADING_PEMBAYARAN',
          payload: false
        })

        dispatch({
          type: 'SET_MESSAGE',
          payload: response.data.message
        })

        if (response.data.success) {
          dispatch({
            type: 'SET_PROCESSED_PEMBAYARAN',
            payload: response.data.data
          })
        }
      })
      .catch((error) => {
        fetch_error(error, dispatch)

        dispatch({
          type: 'SET_LOADING_PEMBAYARAN',
          payload: false
        })
      })
  }
}

export function doUpdatePembayaran(data) {
  return (dispatch, store) => {
    // console.log(data)

    const id = store().pembayaran.current_data.id

    data._method = 'POST'

    const options = {
      method: 'POST',
      headers: set_auth_header(store),
      url: process.env.REACT_APP_API_URL + '/pembayaran/validasi/' + id,
      data: secure_data(data)
    }

    dispatch({
      type: 'SET_VALIDATION',
      payload: []
    })

    dispatch({
      type: 'SET_LOADING_PEMBAYARAN',
      payload: true
    })

    axios(options)
      .then(function (response) {
        // console.log(response.data)

        dispatch({
          type: 'SET_LOADING_PEMBAYARAN',
          payload: false
        })

        dispatch({
          type: 'SET_MESSAGE',
          payload: response.data.message
        })

        if (response.data.success) {
          dispatch({
            type: 'SET_PROCESSED_PEMBAYARAN',
            payload: response.data.data
          })

          dispatch({
            type: 'SET_FORM_PEMBAYARAN',
            payload: false
          })
        }
      })
      .catch((error) => {
        fetch_error(error, dispatch)

        dispatch({
          type: 'SET_LOADING_PEMBAYARAN',
          payload: false
        })
      })
  }
}

export function doCreatePembayaran(data) {
  return (dispatch, store) => {
    // console.log(data)

    data._method = 'POST'

    const options = {
      method: 'POST',
      headers: set_auth_header(store),
      url: process.env.REACT_APP_API_URL + '/pembayaran/create',
      data: secure_data(data)
    }

    dispatch({
      type: 'SET_VALIDATION',
      payload: []
    })

    dispatch({
      type: 'SET_LOADING_PEMBAYARAN',
      payload: true
    })

    axios(options)
      .then(function (response) {
        // console.log(response.data)

        dispatch({
          type: 'SET_LOADING_PEMBAYARAN',
          payload: false
        })

        dispatch({
          type: 'SET_MESSAGE',
          payload: response.data.message
        })

        dispatch({
          type: 'SET_ACTION',
          payload: null
        })

        if (response.data.success) {
          dispatch({
            type: 'SET_ACTION',
            payload: 'CREATE_SUCCESS'
          })
          dispatch({
            type: 'SET_PROCESSED_PEMBAYARAN',
            payload: response.data.data
          })

          dispatch({
            type: 'SET_FORM_PEMBAYARAN',
            payload: false
          })
        }
      })
      .catch((error) => {
        fetch_error(error, dispatch)
        dispatch({
          type: 'SET_ACTION',
          payload: 'CREATE_FAILED'
        })

        dispatch({
          type: 'SET_LOADING_PEMBAYARAN',
          payload: false
        })
      })
  }
}

export function doCreatePembayaranKolektif(data) {
  return (dispatch, store) => {
    // console.log(data)

    data._method = 'POST'

    const options = {
      method: 'POST',
      headers: set_auth_header(store),
      url: process.env.REACT_APP_API_URL + '/pembayaran_kolektif/create',
      data: secure_data(data)
    }

    dispatch({
      type: 'SET_VALIDATION',
      payload: []
    })

    dispatch({
      type: 'SET_LOADING_PEMBAYARAN',
      payload: true
    })

    axios(options)
      .then(function (response) {
        // console.log(response.data)

        dispatch({
          type: 'SET_LOADING_PEMBAYARAN',
          payload: false
        })

        dispatch({
          type: 'SET_MESSAGE',
          payload: response.data.message
        })

        if (response.data.success) {
          dispatch({
            type: 'SET_ACTION',
            payload: 'CREATE_SUCCESS'
          })
          dispatch({
            type: 'SET_PROCESSED_PEMBAYARAN',
            payload: response.data.data
          })

          dispatch({
            type: 'SET_FORM_PEMBAYARAN',
            payload: false
          })
        }
      })
      .catch((error) => {
        fetch_error(error, dispatch)

        dispatch({
          type: 'SET_ACTION',
          payload: 'CREATE_FAILED'
        })

        dispatch({
          type: 'SET_LOADING_PEMBAYARAN',
          payload: false
        })
      })
  }
}

export function getPembayaranAnggota(
  id,
  page = 0,
  load = 5,
  keyword = '',
  sorted = [],
  filter_provinsi = null,
  filter_kabupaten = null,
  filter_kecamatan = null
) {
  return (dispatch, store) => {
    const url = process.env.REACT_APP_API_URL + '/pembayaran_anggota?'

    const options = {
      data: secure_data({
        page,
        load,
        keyword,
        sorted,
        filter_provinsi,
        filter_kabupaten,
        filter_kecamatan
      }),
      method: 'POST',
      headers: set_auth_header(store),
      url
    }

    dispatch({
      type: 'SET_VALIDATION',
      payload: []
    })

    dispatch({
      type: 'SET_LOADING_PEMBAYARAN_ANGGOTA',
      payload: true
    })

    axios(options)
      .then(function (response) {
        // console.log(response.data)
        dispatch({
          type: 'SET_LOADING_PEMBAYARAN_ANGGOTA',
          payload: false
        })
        dispatch({
          type: 'SET_PEMBAYARAN_ANGGOTA',
          payload: response.data
        })
      })
      .catch((error) => {
        fetch_error(error, dispatch)
        dispatch({
          type: 'SET_LOADING_PEMBAYARAN_ANGGOTA',
          payload: false
        })
      })
  }
}

export function getPembayaran(
  filter_provinsi = null,
  filter_kabupaten = null,
  filter_kecamatan = null,
  id,
  page = 0,
  load = 5,
  keyword = '',
  sorted = []
) {
  return (dispatch, store) => {
    let url = process.env.REACT_APP_API_URL + '/pembayaran?'

    if (id) {
      url += '&id=' + id
    }

    const options = {
      data: secure_data({
        page,
        load,
        keyword,
        sorted,
        filter_provinsi,
        filter_kabupaten,
        filter_kecamatan
      }),
      method: 'POST',
      headers: set_auth_header(store),
      url
    }

    dispatch({
      type: 'SET_VALIDATION',
      payload: []
    })

    dispatch({
      type: 'SET_LOADING_PEMBAYARAN',
      payload: true
    })

    dispatch({
      type: 'SET_ACTION',
      payload: null
    })

    axios(options)
      .then(function (response) {
        // console.log(response.data)
        dispatch({
          type: 'SET_ACTION',
          payload: 'GET_SUCCESS'
        })
        dispatch({
          type: 'SET_LOADING_PEMBAYARAN',
          payload: false
        })
        dispatch({
          type: 'SET_PEMBAYARAN',
          payload: response.data
        })
      })
      .catch((error) => {
        fetch_error(error, dispatch)
        dispatch({
          type: 'SET_ACTION',
          payload: 'GET_FAILED'
        })
        dispatch({
          type: 'SET_LOADING_PEMBAYARAN',
          payload: false
        })
      })
  }
}

export function getPembayaranUser(kta) {
  return (dispatch, store) => {
    const url = process.env.REACT_APP_API_URL + '/pembayaran_user?'

    try {
      const options = {
        data: secure_data({
          kta
        }),
        method: 'POST',
        headers: set_auth_header(store),
        url
      }

      axios(options)
        .then(function (response) {
          // console.log(response.data)
          // dispatch({
          //   type: 'SET_LOADING_ANGGOTA',
          //   payload: false
          // })
          dispatch({
            type: 'SET_PEMBAYARAN_BY_ID',
            payload: response.data
          })
        })
        .catch((error) => {
          fetch_error(error, dispatch)
          // dispatch({
          //   type: 'SET_LOADING_ANGGOTA',
          //   payload: false
          // })
        })
    } catch (e) {}
  }
}

export function getPembayaranAll(no_kta_anggota, kategori, date) {
  return (dispatch, store) => {
    const url = process.env.REACT_APP_API_URL + '/pembayaran_all?'

    try {
      const options = {
        data: secure_data({
          no_kta_anggota,
          kategori,
          date
        }),
        method: 'POST',
        headers: set_auth_header(store),
        url
      }

      axios(options)
        .then(function (response) {
          // console.log(response.data)
          // dispatch({
          //   type: 'SET_LOADING_ANGGOTA',
          //   payload: false
          // })
          dispatch({
            type: 'SET_PEMBAYARAN_ALL',
            payload: response.data
          })
        })
        .catch((error) => {
          fetch_error(error, dispatch)
          // dispatch({
          //   type: 'SET_LOADING_ANGGOTA',
          //   payload: false
          // })
        })
    } catch (e) {}
  }
}

export default function pembayaran(state = initialState, action) {
  switch (action.type) {
    case 'SET_PEMBAYARAN':
      return update(state, {
        response: {
          data: {
            $set: action.payload
          }
        },
        processed_data: {
          $set: null
        }
      })

    case 'SET_PEMBAYARAN_ALL':
      return update(state, {
        response: {
          data_all: {
            $set: action.payload
          }
        },
        processed_data: {
          $set: null
        }
      })

    case 'SET_ACTION':
      return update(state, {
        action: {
          $set: action.payload
        }
      })

    case 'SET_PEMBAYARAN_ANGGOTA':
      return update(state, {
        response: {
          create: {
            $set: action.payload
          }
        },
        processed_data: {
          $set: null
        }
      })

    case 'RESET_PEMBAYARAN':
      return update(state, {
        current_data: {
          $set: null
        },
        processed_data: {
          $set: null
        }
      })

    case 'SET_CURRENT_PEMBAYARAN':
      return update(state, {
        current_data: {
          $set: action.payload
        }
      })

    case 'SET_PROCESSED_PEMBAYARAN':
      return update(state, {
        processed_data: {
          $set: action.payload
        }
      })

    case 'SET_LOADING_PEMBAYARAN':
      return update(state, {
        loading: {
          $set: action.payload
        }
      })

    case 'SET_LOADING_PEMBAYARAN_ANGGOTA':
      return update(state, {
        loading_anggota: {
          $set: action.payload
        }
      })

    case 'SET_TOTAL_PEMBAYARAN':
      return update(state, {
        total: {
          $set: action.payload
        }
      })

    case 'SET_FORM_PEMBAYARAN':
      return update(state, {
        form: {
          $set: action.payload
        }
      })

    // case 'FIND_PEMBAYARAN':
    // let find = _.find(state.response, function (o) {
    // return o.id < action.payload
    // })
    //
    // return update(state, {
    //   current_data: {
    //     $set: find
    //   }
    // })

    default:
      return state
  }
}
