import update from 'immutability-helper'

import axios from 'axios'
// import _ from 'lodash'

import { fetch_error, secure_data, set_auth_header } from './index'

const initialState = {
  total: 0,
  response: [],
  loading: false,
  current_data: {},
  form: null,
  processed_data: null,
  date: new Date()
}

export function setCurrentHobi(data) {
  return (dispatch, store) => {
    dispatch({
      type: 'SET_CURRENT_HOBI',
      payload: data
    })
  }
}

export function resetHobi(bool) {
  return (dispatch, store) => {
    dispatch({
      type: 'RESET_HOBI',
      payload: null
    })
  }
}

export function setFormHobi(bool) {
  return (dispatch, store) => {
    dispatch({
      type: 'SET_FORM_HOBI',
      payload: bool
    })
  }
}

export function doDeleteHobi(id) {
  return (dispatch, store) => {
    // console.log(id)
    const data = { _method: 'POST' }
    const options = {
      method: 'POST',
      headers: set_auth_header(store),
      url: process.env.REACT_APP_API_URL + '/hobi/delete/' + id,
      data: secure_data(data)
    }

    dispatch({
      type: 'SET_VALIDATION',
      payload: []
    })

    dispatch({
      type: 'SET_LOADING_HOBI',
      payload: true
    })

    axios(options)
      .then(function (response) {
        // console.log(response.data)

        dispatch({
          type: 'SET_LOADING_HOBI',
          payload: false
        })

        dispatch({
          type: 'SET_MESSAGE',
          payload: response.data.message
        })

        if (response.data.success) {
          dispatch({
            type: 'SET_PROCESSED_HOBI',
            payload: response.data.data
          })
        }
      })
      .catch((error) => {
        fetch_error(error, dispatch)

        dispatch({
          type: 'SET_LOADING_HOBI',
          payload: false
        })
      })
  }
}

export function doUpdateHobi(data) {
  return (dispatch, store) => {
    // console.log(data)

    const id = store().hobi.current_data.id

    data._method = 'POST'

    const options = {
      method: 'POST',
      headers: set_auth_header(store),
      url: process.env.REACT_APP_API_URL + '/hobi/update/' + id,
      data: secure_data(data)
    }

    dispatch({
      type: 'SET_VALIDATION',
      payload: []
    })

    dispatch({
      type: 'SET_LOADING_HOBI',
      payload: true
    })

    axios(options)
      .then(function (response) {
        // console.log(response.data)

        dispatch({
          type: 'SET_LOADING_HOBI',
          payload: false
        })

        dispatch({
          type: 'SET_MESSAGE',
          payload: response.data.message
        })

        if (response.data.success) {
          dispatch({
            type: 'SET_PROCESSED_HOBI',
            payload: response.data.data
          })

          dispatch({
            type: 'SET_FORM_HOBI',
            payload: false
          })
        }
      })
      .catch((error) => {
        fetch_error(error, dispatch)

        dispatch({
          type: 'SET_LOADING_HOBI',
          payload: false
        })
      })
  }
}

export function doCreateHobi(data) {
  return (dispatch, store) => {
    // console.log(data)

    data._method = 'POST'

    const options = {
      method: 'POST',
      headers: set_auth_header(store),
      url: process.env.REACT_APP_API_URL + '/hobi/create',
      data: secure_data(data)
    }

    dispatch({
      type: 'SET_VALIDATION',
      payload: []
    })

    dispatch({
      type: 'SET_LOADING_HOBI',
      payload: true
    })

    axios(options)
      .then(function (response) {
        // console.log(response.data)

        dispatch({
          type: 'SET_LOADING_HOBI',
          payload: false
        })

        dispatch({
          type: 'SET_MESSAGE',
          payload: response.data.message
        })

        if (response.data.success) {
          dispatch({
            type: 'SET_PROCESSED_HOBI',
            payload: response.data.data
          })

          dispatch({
            type: 'SET_FORM_HOBI',
            payload: false
          })
        }
      })
      .catch((error) => {
        fetch_error(error, dispatch)

        dispatch({
          type: 'SET_LOADING_HOBI',
          payload: false
        })
      })
  }
}

export function getHobi(no_kta_anggota) {
  return (dispatch, store) => {
    const url = process.env.REACT_APP_API_URL + '/hobi?'

    // if (id) {
    //   url += '&id=' + id
    // }

    const options = {
      data: secure_data({ no_kta_anggota }),
      method: 'POST',
      headers: set_auth_header(store),
      url
    }

    dispatch({
      type: 'SET_LOADING_HOBI',
      payload: true
    })

    axios(options)
      .then(function (response) {
        // console.log(response.data)
        dispatch({
          type: 'SET_LOADING_HOBI',
          payload: false
        })
        dispatch({
          type: 'SET_HOBI',
          payload: response.data
        })
        // dispatch({
        //   type: 'SET_VALIDATION',
        //   payload: []
        // })
      })
      .catch((error) => {
        fetch_error(error, dispatch)
        dispatch({
          type: 'SET_LOADING_HOBI',
          payload: false
        })
      })
  }
}

export default function hobi(state = initialState, action) {
  switch (action.type) {
    case 'SET_HOBI':
      return update(state, {
        response: {
          $set: action.payload
        },
        processed_data: {
          $set: null
        }
      })

    case 'RESET_HOBI':
      return update(state, {
        current_data: {
          $set: null
        },
        processed_data: {
          $set: null
        }
      })

    case 'SET_CURRENT_HOBI':
      return update(state, {
        current_data: {
          $set: action.payload
        }
      })

    case 'SET_PROCESSED_HOBI':
      return update(state, {
        processed_data: {
          $set: action.payload
        }
      })

    case 'SET_LOADING_HOBI':
      return update(state, {
        loading: {
          $set: action.payload
        }
      })

    case 'SET_TOTAL_HOBI':
      return update(state, {
        total: {
          $set: action.payload
        }
      })

    case 'SET_FORM_HOBI':
      return update(state, {
        form: {
          $set: action.payload
        }
      })

    // case 'FIND_HOBI':
    // let find = _.find(state.response, function (o) {
    // return o.id < action.payload
    // })
    //
    // return update(state, {
    //   current_data: {
    //     $set: find
    //   }
    // })

    default:
      return state
  }
}
