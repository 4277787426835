import update from 'immutability-helper'

import axios from 'axios'
// import _ from 'lodash'

import { fetch_error, secure_data, set_auth_header } from './index'

const initialState = {
  total: 0,
  response: [],
  loading: false,
  current_data: {},
  form: null,
  processed_data: null,
  date: new Date()
}

export function getAnggotaKaderisasi(
  filter_provinsi = null,
  filter_kabupaten = null,
  filter_kecamatan = null,
  page = 0,
  load = 5,
  keyword = '',
  sorted = []
) {
  return (dispatch, store) => {
    const url = process.env.REACT_APP_API_URL + '/anggota_kaderisasi?'

    // if (id) {
    //   url += '&id=' + id
    // }

    // if (filter_provinsi) {
    //   url += '&filter_provinsi=' + filter_provinsi
    // }
    //
    // if (filter_kabupaten) {
    //   url += '&filter_kabupaten=' + filter_kabupaten
    // }
    //
    // if (filter_kecamatan) {
    //   url += '&filter_kecamatan=' + filter_kecamatan
    // }
    //
    // if (email) {
    //   url += '&email=' + email
    // }
    const options = {
      data: secure_data({
        filter_provinsi,
        filter_kabupaten,
        filter_kecamatan,
        page,
        load,
        keyword,
        sorted
      }),
      method: 'POST',
      headers: set_auth_header(store),
      url
    }

    dispatch({
      type: 'SET_VALIDATION',
      payload: []
    })

    dispatch({
      type: 'SET_LOADING_ANGGOTA_KADERISASI',
      payload: true
    })

    axios(options)
      .then(function (response) {
        // console.log(response.data)
        dispatch({
          type: 'SET_LOADING_ANGGOTA_KADERISASI',
          payload: false
        })
        dispatch({
          type: 'SET_ANGGOTA_KADERISASI',
          payload: response.data
        })
      })
      .catch((error) => {
        fetch_error(error, dispatch)
        dispatch({
          type: 'SET_LOADING_ANGGOTA_KADERISASI',
          payload: false
        })
      })
  }
}

export default function anggota_kaderisasi(state = initialState, action) {
  switch (action.type) {
    case 'SET_ANGGOTA_KADERISASI':
      return update(state, {
        response: {
          $set: action.payload
        },
        processed_data: {
          $set: null
        }
      })

    case 'RESET_ANGGOTA_KADERISASI':
      return update(state, {
        current_data: {
          $set: null
        },
        processed_data: {
          $set: null
        }
      })

    case 'SET_CURRENT_ANGGOTA_KADERISASI':
      return update(state, {
        current_data: {
          $set: action.payload
        }
      })

    case 'SET_PROCESSED_ANGGOTA_KADERISASI':
      return update(state, {
        processed_data: {
          $set: action.payload
        }
      })

    case 'SET_LOADING_ANGGOTA_KADERISASI':
      return update(state, {
        loading: {
          $set: action.payload
        }
      })

    case 'SET_TOTAL_ANGGOTA_KADERISASI':
      return update(state, {
        total: {
          $set: action.payload
        }
      })

    case 'SET_FORM_ANGGOTA_KADERISASI':
      return update(state, {
        form: {
          $set: action.payload
        }
      })

    // case 'FIND_ANGGOTA_KADERISASI':
    // let find = _.find(state.response, function (o) {
    // return o.id < action.payload
    // })
    //
    // return update(state, {
    //   current_data: {
    //     $set: find
    //   }
    // })

    default:
      return state
  }
}
