import update from 'immutability-helper'

import axios from 'axios'
// import _ from 'lodash'

import { fetch_error, secure_data, set_auth_header } from './index'

const initialState = {
  total: 0,
  response: [],
  loading: false,
  current_data: {},
  form: null,
  processed_data: null,
  date: new Date()
}

export function getPembayaranKta(kta) {
  return (dispatch, store) => {
    const url = process.env.REACT_APP_API_URL + '/pembayaran_kta?'

    try {
      const options = {
        data: secure_data({
          kta
        }),
        method: 'POST',
        headers: set_auth_header(store),
        url
      }

      dispatch({
        type: 'SET_LOADING_PEMBAYARAN_KTA',
        payload: true
      })

      axios(options)
        .then(function (response) {
          // console.log(response.data)
          dispatch({
            type: 'SET_PEMBAYARAN_KTA',
            payload: response.data
          })
          dispatch({
            type: 'SET_LOADING_PEMBAYARAN_KTA',
            payload: false
          })
        })
        .catch((error) => {
          fetch_error(error, dispatch)
          dispatch({
            type: 'SET_LOADING_PEMBAYARAN_KTA',
            payload: false
          })
        })
    } catch (e) {}
  }
}

export default function pembayaran_kta(state = initialState, action) {
  switch (action.type) {
    case 'SET_PEMBAYARAN_KTA':
      return update(state, {
        response: {
          $set: action.payload
        },
        processed_data: {
          $set: null
        }
      })

    case 'RESET_PEMBAYARAN_KTA':
      return update(state, {
        current_data: {
          $set: null
        },
        processed_data: {
          $set: null
        }
      })

    case 'SET_CURRENT_PEMBAYARAN_KTA':
      return update(state, {
        current_data: {
          $set: action.payload
        }
      })

    case 'SET_PROCESSED_PEMBAYARAN_KTA':
      return update(state, {
        processed_data: {
          $set: action.payload
        }
      })

    case 'SET_LOADING_PEMBAYARAN_KTA':
      return update(state, {
        loading: {
          $set: action.payload
        }
      })

    case 'SET_TOTAL_PEMBAYARAN_KTA':
      return update(state, {
        total: {
          $set: action.payload
        }
      })

    case 'SET_FORM_PEMBAYARAN_KTA':
      return update(state, {
        form: {
          $set: action.payload
        }
      })

    // case 'FIND_PEMBAYARAN_KTA':
    // let find = _.find(state.response, function (o) {
    // return o.id < action.payload
    // })
    //
    // return update(state, {
    //   current_data: {
    //     $set: find
    //   }
    // })

    default:
      return state
  }
}
