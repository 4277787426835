import update from 'immutability-helper'

import axios from 'axios'
// import _ from 'lodash'

import { fetch_error, secure_data, set_auth_header } from './index'

const initialState = {
  total: 0,
  response: [],
  loading: false,
  current_data: {},
  form: null,
  processed_data: null,
  date: new Date()
}

export function setCurrentRiwayatLogin(data) {
  return (dispatch, store) => {
    dispatch({
      type: 'SET_CURRENT_RIWAYAT_LOGIN',
      payload: data
    })
  }
}

export function resetRiwayatLogin(bool) {
  return (dispatch, store) => {
    dispatch({
      type: 'RESET_RIWAYAT_LOGIN',
      payload: null
    })
  }
}

export function setFormRiwayatLogin(bool) {
  return (dispatch, store) => {
    dispatch({
      type: 'SET_FORM_RIWAYAT_LOGIN',
      payload: bool
    })
  }
}

export function getRiwayatLogin(
  page = 0,
  load = 5,
  keyword = '',
  sorted = [],
  exp
) {
  return (dispatch, store) => {
    const url = process.env.REACT_APP_API_URL + '/riwayat_login?'

    // if (id) {
    //   url += '&id=' + id
    // }

    const options = {
      data: secure_data({
        page,
        load,
        keyword,
        sorted,
        exp
      }),
      method: 'POST',
      headers: set_auth_header(store),
      url
    }

    dispatch({
      type: 'SET_LOADING_RIWAYAT_LOGIN',
      payload: true
    })

    axios(options)
      .then(function (response) {
        // console.log(response.data)
        dispatch({
          type: 'SET_LOADING_RIWAYAT_LOGIN',
          payload: false
        })

        if (response.data.success) {
          dispatch({
            type: 'SET_RIWAYAT_LOGIN',
            payload: response.data
          })
        } else {
          dispatch({
            type: 'SET_MESSAGE',
            payload: response.data.message
          })
        }
      })
      .catch((error) => {
        fetch_error(error, dispatch)
        dispatch({
          type: 'SET_LOADING_RIWAYAT_LOGIN',
          payload: false
        })
      })
  }
}

export default function riwayat_login(state = initialState, action) {
  switch (action.type) {
    case 'SET_RIWAYAT_LOGIN':
      return update(state, {
        response: {
          $set: action.payload
        },
        processed_data: {
          $set: null
        }
      })

    case 'RESET_RIWAYAT_LOGIN':
      return update(state, {
        current_data: {
          $set: null
        },
        processed_data: {
          $set: null
        }
      })

    case 'SET_CURRENT_RIWAYAT_LOGIN':
      return update(state, {
        current_data: {
          $set: action.payload
        }
      })

    case 'SET_PROCESSED_RIWAYAT_LOGIN':
      return update(state, {
        processed_data: {
          $set: action.payload
        }
      })

    case 'SET_LOADING_RIWAYAT_LOGIN':
      return update(state, {
        loading: {
          $set: action.payload
        }
      })

    case 'SET_TOTAL_RIWAYAT_LOGIN':
      return update(state, {
        total: {
          $set: action.payload
        }
      })

    case 'SET_FORM_RIWAYAT_LOGIN':
      return update(state, {
        form: {
          $set: action.payload
        }
      })

    // // case 'FIND_RIWAYAT_LOGIN':
    //   // let find = _.find(state.response, function (o) {
    //     // return o.id < action.payload
    //   })

    //   return update(state, {
    //     current_data: {
    //       $set: find
    //     }
    //   })

    default:
      return state
  }
}
