import update from 'immutability-helper'

import axios from 'axios'
// import _ from 'lodash'

import { fetch_error, secure_data, set_auth_header } from './index'

const initialState = {
  total: 0,
  response: [],
  loading: false,
  current_data: {},
  form: null,
  processed_data: null,
  date: new Date()
}

export function setCurrentKepengurusanAnggota(data) {
  return (dispatch, store) => {
    dispatch({
      type: 'SET_CURRENT_KEPENGURUSAN_ANGGOTA',
      payload: data
    })
  }
}

export function setLoadingKepengurusanAnggota(data) {
  return (dispatch, store) => {
    dispatch({
      type: 'SET_LOADING_KEPENGURUSAN_ANGGOTA',
      payload: data
    })
  }
}

export function resetKepengurusanAnggota(bool) {
  return (dispatch, store) => {
    dispatch({
      type: 'RESET_KEPENGURUSAN_ANGGOTA',
      payload: null
    })
  }
}

export function setFormKepengurusanAnggota(bool) {
  return (dispatch, store) => {
    dispatch({
      type: 'SET_FORM_KEPENGURUSAN_ANGGOTA',
      payload: bool
    })
  }
}

export function doDeleteKepengurusanAnggota(x) {
  return (dispatch, store) => {
    // console.log(x)

    const data = {
      id: x.id,
      file_sk: x.file_sk,
      keterangan: x.keterangan,
      tanggal_sk: x.tanggal_sk,
      _method: 'POST'
    }

    const options = {
      method: 'POST',
      headers: set_auth_header(store),
      url: process.env.REACT_APP_API_URL + '/kepengurusan/delete/' + data.id,
      data: secure_data(data)
    }

    dispatch({
      type: 'SET_VALIDATION',
      payload: []
    })

    dispatch({
      type: 'SET_LOADING_KEPENGURUSAN_ANGGOTA',
      payload: true
    })

    axios(options)
      .then(function (response) {
        // console.log(response.data)

        dispatch({
          type: 'SET_LOADING_KEPENGURUSAN_ANGGOTA',
          payload: false
        })

        dispatch({
          type: 'SET_MESSAGE',
          payload: response.data.message
        })

        if (response.status) {
          dispatch({
            type: 'SET_PROCESSED_KEPENGURUSAN_ANGGOTA',
            payload: response.data.data
          })
        }
      })
      .catch((error) => {
        fetch_error(error, dispatch)

        dispatch({
          type: 'SET_LOADING_KEPENGURUSAN_ANGGOTA',
          payload: false
        })
      })
  }
}

export function doUpdateKepengurusanAnggota(data) {
  return (dispatch, store) => {
    // console.log(data)

    const id = data.id

    data._method = 'POST'

    const options = {
      method: 'POST',
      headers: set_auth_header(store),
      url: process.env.REACT_APP_API_URL + '/kepengurusan/update/' + id,
      data: secure_data(data)
    }

    dispatch({
      type: 'SET_VALIDATION',
      payload: []
    })

    dispatch({
      type: 'SET_LOADING_KEPENGURUSAN_ANGGOTA',
      payload: true
    })

    axios(options)
      .then(function (response) {
        // console.log(response.data)

        dispatch({
          type: 'SET_LOADING_KEPENGURUSAN_ANGGOTA',
          payload: false
        })

        dispatch({
          type: 'SET_MESSAGE',
          payload: response.data.message
        })

        if (response.status) {
          dispatch({
            type: 'SET_PROCESSED_KEPENGURUSAN_ANGGOTA',
            payload: response.data.data
          })

          dispatch({
            type: 'SET_FORM_KEPENGURUSAN_ANGGOTA',
            payload: false
          })
        }
      })
      .catch((error) => {
        fetch_error(error, dispatch)

        dispatch({
          type: 'SET_LOADING_KEPENGURUSAN_ANGGOTA',
          payload: false
        })
      })
  }
}

export function doCreateKepengurusanAnggota(data) {
  return (dispatch, store) => {
    // console.log(data)

    data._method = 'POST'
    // data.token = store().auth.token

    const options = {
      method: 'POST',
      headers: set_auth_header(store),
      url: process.env.REACT_APP_API_URL + '/kepengurusan/create',
      data: secure_data(data)
    }

    dispatch({
      type: 'SET_VALIDATION',
      payload: []
    })

    dispatch({
      type: 'SET_LOADING_KEPENGURUSAN_ANGGOTA',
      payload: true
    })

    axios(options)
      .then(function (response) {
        // console.log(response.data)

        dispatch({
          type: 'SET_LOADING_KEPENGURUSAN_ANGGOTA',
          payload: false
        })

        dispatch({
          type: 'SET_MESSAGE',
          payload: response.data.message
        })

        if (response.status) {
          dispatch({
            type: 'SET_PROCESSED_KEPENGURUSAN_ANGGOTA',
            payload: response.data.data
          })

          dispatch({
            type: 'SET_FORM_KEPENGURUSAN_ANGGOTA',
            payload: false
          })
        }
      })
      .catch((error) => {
        fetch_error(error, dispatch)

        dispatch({
          type: 'SET_LOADING_KEPENGURUSAN_ANGGOTA',
          payload: false
        })
      })
  }
}

export function getKepengurusanAnggota(
  provinsi,
  kabupaten,
  list_pengurus_selected
) {
  return (dispatch, store) => {
    const options = {
      method: 'POST',
      headers: set_auth_header(store),
      url: process.env.REACT_APP_API_URL + '/kepengurusan_anggota?',
      data: secure_data({
        provinsi,
        kabupaten,
        list_pengurus_selected
      })
    }

    dispatch({
      type: 'SET_LOADING_KEPENGURUSAN_ANGGOTA',
      payload: true
    })

    axios(options)
      .then(function (response) {
        // console.log(response.data)

        dispatch({
          type: 'SET_LOADING_KEPENGURUSAN_ANGGOTA',
          payload: false
        })
        dispatch({
          type: 'SET_KEPENGURUSAN_ANGGOTA',
          payload: response.data
        })

        dispatch({
          type: 'SET_MESSAGE',
          payload: response.data.message
        })

        if (response.status) {
          dispatch({
            type: 'SET_PROCESSED_KEPENGURUSAN_ANGGOTA',
            payload: response.data.data
          })

          dispatch({
            type: 'SET_LOADING_KEPENGURUSAN_ANGGOTA',
            payload: false
          })

          dispatch({
            type: 'SET_FORM_KEPENGURUSAN_ANGGOTA',
            payload: false
          })
        }
      })
      .catch((error) => {
        fetch_error(error, dispatch)

        dispatch({
          type: 'SET_LOADING_KEPENGURUSAN_ANGGOTA',
          payload: false
        })
      })
  }
}

export default function kepengurusan_anggota(state = initialState, action) {
  switch (action.type) {
    case 'SET_KEPENGURUSAN_ANGGOTA':
      return update(state, {
        response: {
          $set: action.payload
        },
        processed_data: {
          $set: null
        }
      })

    case 'RESET_KEPENGURUSAN_ANGGOTA':
      return update(state, {
        current_data: {
          $set: null
        },
        processed_data: {
          $set: null
        }
      })

    case 'SET_CURRENT_KEPENGURUSAN_ANGGOTA':
      return update(state, {
        current_data: {
          $set: action.payload
        }
      })

    case 'SET_PROCESSED_KEPENGURUSAN_ANGGOTA':
      return update(state, {
        processed_data: {
          $set: action.payload
        }
      })

    case 'SET_LOADING_KEPENGURUSAN_ANGGOTA':
      return update(state, {
        loading: {
          $set: action.payload
        }
      })

    case 'SET_TOTAL_KEPENGURUSAN_ANGGOTA':
      return update(state, {
        total: {
          $set: action.payload
        }
      })

    case 'SET_FORM_KEPENGURUSAN_ANGGOTA':
      return update(state, {
        form: {
          $set: action.payload
        }
      })

    // case 'FIND_KEPENGURUSAN_ANGGOTA':
    // let find = _.find(state.response, function (o) {
    // return o.id < action.payload
    // })
    //
    // return update(state, {
    //   current_data: {
    //     $set: find
    //   }
    // })

    default:
      return state
  }
}
