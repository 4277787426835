import update from 'immutability-helper'

import axios from 'axios'
// import _ from 'lodash'

import { fetch_error, secure_data, set_auth_header } from './index'

const initialState = {
  total: 0,
  response: [],
  grafik: [],
  loading: false,
  loading_grafik: false,
  current_data: {},
  form: null,
  processed_data: null,
  date: new Date()
}

export function setCurrentTodoDashboard(data) {
  return (dispatch, store) => {
    dispatch({
      type: 'SET_CURRENT_TODO_DASHBOARD',
      payload: data
    })
  }
}

export function resetTodoDashboard(bool) {
  return (dispatch, store) => {
    dispatch({
      type: 'RESET_TODO_DASHBOARD',
      payload: null
    })
  }
}

export function setFormTodoDashboard(bool) {
  return (dispatch, store) => {
    dispatch({
      type: 'SET_FORM_TODO_DASHBOARD',
      payload: bool
    })
  }
}

export function doDeleteTodoDashboard(id) {
  return (dispatch, store) => {
    // console.log(id)
    const data = { _method: 'POST' }
    const options = {
      method: 'POST',
      headers: set_auth_header(store),
      url: process.env.REACT_APP_API_URL + '/admin_users/delete/' + id,
      data: secure_data(data)
    }

    dispatch({
      type: 'SET_VALIDATION',
      payload: []
    })

    dispatch({
      type: 'SET_LOADING_TODO_DASHBOARD',
      payload: true
    })

    axios(options)
      .then(function (response) {
        // console.log(response.data)

        dispatch({
          type: 'SET_LOADING_TODO_DASHBOARD',
          payload: false
        })

        dispatch({
          type: 'SET_MESSAGE',
          payload: response.data.message
        })

        if (response.data.success) {
          dispatch({
            type: 'SET_PROCESSED_TODO_DASHBOARD',
            payload: response.data.data
          })
        }
      })
      .catch((error) => {
        fetch_error(error, dispatch)

        dispatch({
          type: 'SET_LOADING_TODO_DASHBOARD',
          payload: false
        })
      })
  }
}

export function doUpdateTodoDashboard(data) {
  return (dispatch, store) => {
    // console.log(data)

    const id = store().admin_users.current_data.id

    data._method = 'POST'

    const options = {
      method: 'POST',
      headers: set_auth_header(store),
      url: process.env.REACT_APP_API_URL + '/admin_users/update/' + id,
      data: secure_data(data)
    }

    dispatch({
      type: 'SET_VALIDATION',
      payload: []
    })

    dispatch({
      type: 'SET_LOADING_TODO_DASHBOARD',
      payload: true
    })

    axios(options)
      .then(function (response) {
        // console.log(response.data)

        dispatch({
          type: 'SET_LOADING_TODO_DASHBOARD',
          payload: false
        })

        dispatch({
          type: 'SET_MESSAGE',
          payload: response.data.message
        })

        if (response.data.success) {
          dispatch({
            type: 'SET_PROCESSED_TODO_DASHBOARD',
            payload: response.data.data
          })

          dispatch({
            type: 'SET_FORM_TODO_DASHBOARD',
            payload: false
          })
        }
      })
      .catch((error) => {
        fetch_error(error, dispatch)

        dispatch({
          type: 'SET_LOADING_TODO_DASHBOARD',
          payload: false
        })
      })
  }
}

export function doCreateTodoDashboard(data) {
  return (dispatch, store) => {
    // console.log(data)

    data._method = 'POST'

    const options = {
      method: 'POST',
      headers: set_auth_header(store),
      url: process.env.REACT_APP_API_URL + '/admin_users/create',
      data: secure_data(data)
    }

    dispatch({
      type: 'SET_VALIDATION',
      payload: []
    })

    dispatch({
      type: 'SET_LOADING_TODO_DASHBOARD',
      payload: true
    })

    axios(options)
      .then(function (response) {
        // console.log(response.data)

        dispatch({
          type: 'SET_LOADING_TODO_DASHBOARD',
          payload: false
        })

        dispatch({
          type: 'SET_MESSAGE',
          payload: response.data.message
        })

        if (response.data.success) {
          dispatch({
            type: 'SET_PROCESSED_TODO_DASHBOARD',
            payload: response.data.data
          })

          dispatch({
            type: 'SET_FORM_TODO_DASHBOARD',
            payload: false
          })
        }
      })
      .catch((error) => {
        fetch_error(error, dispatch)

        dispatch({
          type: 'SET_LOADING_TODO_DASHBOARD',
          payload: false
        })
      })
  }
}

export function getTodoDashboard(
  filter_dash_provinsi,
  filter_dash_kabupaten,
  filter_dash_kecamatan
) {
  return (dispatch, store) => {
    const url = process.env.REACT_APP_API_URL + '/todo_dashboard?'

    const options = {
      data: secure_data({
        filter_dash_provinsi,
        filter_dash_kabupaten,
        filter_dash_kecamatan
      }),
      method: 'POST',
      headers: set_auth_header(store),
      url
    }

    dispatch({
      type: 'SET_VALIDATION',
      payload: []
    })

    dispatch({
      type: 'SET_LOADING_TODO_DASHBOARD',
      payload: true
    })

    axios(options)
      .then(function (response) {
        // console.log(response.data)
        dispatch({
          type: 'SET_LOADING_TODO_DASHBOARD',
          payload: false
        })
        dispatch({
          type: 'SET_TODO_DASHBOARD',
          payload: response.data
        })
      })
      .catch((error) => {
        fetch_error(error, dispatch)
        dispatch({
          type: 'SET_LOADING_TODO_DASHBOARD',
          payload: false
        })
      })
  }
}

export function getTestTodoDashboard(
  filter_dash_provinsi,
  filter_dash_kabupaten,
  filter_dash_kecamatan
) {
  return (dispatch, store) => {
    const url = process.env.REACT_APP_API_URL + '/test_dashboard?'

    const options = {
      data: secure_data({
        filter_dash_provinsi,
        filter_dash_kabupaten,
        filter_dash_kecamatan
      }),
      method: 'POST',
      headers: set_auth_header(store),
      url
    }

    // dispatch({
    //   type: 'SET_VALIDATION',
    //   payload: []
    // })
    //
    // dispatch({
    //   type: 'SET_LOADING_TODO_DASHBOARD',
    //   payload: true
    // })

    axios(options)
      .then(function (response) {
        // console.log(response.data)
        // dispatch({
        //   type: 'SET_LOADING_TODO_DASHBOARD',
        //   payload: false
        // })
        // dispatch({
        //   type: 'SET_TODO_DASHBOARD',
        //   payload: response.data
        // })
      })
      .catch((error) => {
        console.log(error)
        // fetch_error(error, dispatch)
        // dispatch({
        //   type: 'SET_LOADING_TODO_DASHBOARD',
        //   payload: false
        // })
      })
  }
}

export function getGrafikDashboard(
  filter_provinsi_grafik = null,
  filter_kabupaten_grafik = null,
  filter_kecamatan_grafik = null
) {
  return (dispatch, store) => {
    const url = process.env.REACT_APP_API_URL + '/grafik_dashboard?'

    const options = {
      data: secure_data({
        filter_provinsi_grafik,
        filter_kabupaten_grafik,
        filter_kecamatan_grafik
      }),
      method: 'POST',
      headers: set_auth_header(store),
      url
    }

    dispatch({
      type: 'SET_LOADING_GRAFIK_DASHBOARD',
      payload: true
    })

    axios(options)
      .then(function (response) {
        // console.log(response.data)
        dispatch({
          type: 'SET_LOADING_GRAFIK_DASHBOARD',
          payload: false
        })
        dispatch({
          type: 'SET_GRAFIK_DASHBOARD',
          payload: response.data
        })
      })
      .catch((error) => {
        fetch_error(error, dispatch)
        dispatch({
          type: 'SET_LOADING_GRAFIK_DASHBOARD',
          payload: false
        })
      })
  }
}

export default function todo_dashboard(state = initialState, action) {
  switch (action.type) {
    case 'SET_TODO_DASHBOARD':
      return update(state, {
        response: {
          $set: action.payload
        },
        processed_data: {
          $set: null
        }
      })

    case 'SET_GRAFIK_DASHBOARD':
      return update(state, {
        grafik: {
          $set: action.payload
        },
        processed_data: {
          $set: null
        }
      })

    case 'RESET_TODO_DASHBOARD':
      return update(state, {
        current_data: {
          $set: null
        },
        processed_data: {
          $set: null
        }
      })

    case 'SET_CURRENT_TODO_DASHBOARD':
      return update(state, {
        current_data: {
          $set: action.payload
        }
      })

    case 'SET_PROCESSED_TODO_DASHBOARD':
      return update(state, {
        processed_data: {
          $set: action.payload
        }
      })

    case 'SET_LOADING_TODO_DASHBOARD':
      return update(state, {
        loading: {
          $set: action.payload
        }
      })

    case 'SET_LOADING_GRAFIK_DASHBOARD':
      return update(state, {
        loading_grafik: {
          $set: action.payload
        }
      })

    case 'SET_TOTAL_TODO_DASHBOARD':
      return update(state, {
        total: {
          $set: action.payload
        }
      })

    case 'SET_FORM_TODO_DASHBOARD':
      return update(state, {
        form: {
          $set: action.payload
        }
      })

    // case 'FIND_TODO_DASHBOARD':
    // let find = _.find(state.response, function (o) {
    // return o.id < action.payload
    // })
    //
    // return update(state, {
    //   current_data: {
    //     $set: find
    //   }
    // })

    default:
      return state
  }
}
