import update from 'immutability-helper'

import axios from 'axios'
// import _ from 'lodash'

import { fetch_error, secure_data, set_auth_header } from './index'

export function setCurrentAnggota(data) {
  return (dispatch, store) => {
    dispatch({
      type: 'SET_CURRENT_ANGGOTA',
      payload: data
    })
  }
}

export function resetAnggota(bool) {
  return (dispatch, store) => {
    dispatch({
      type: 'RESET_ANGGOTA',
      payload: null
    })
  }
}

export function setFormAnggota(bool) {
  return (dispatch, store) => {
    dispatch({
      type: 'SET_FORM_ANGGOTA',
      payload: bool
    })
  }
}

export function doDeleteAnggota(id) {
  return (dispatch, store) => {
    // console.log(id)
    const data = { _method: 'POST' }
    const options = {
      method: 'POST',
      headers: set_auth_header(store),
      url: process.env.REACT_APP_API_URL + '/anggota/delete/' + id,
      data: secure_data(data)
    }

    dispatch({
      type: 'SET_VALIDATION',
      payload: []
    })

    dispatch({
      type: 'SET_LOADING_ANGGOTA',
      payload: true
    })

    axios(options)
      .then(function (response) {
        // console.log(response.data)

        dispatch({
          type: 'SET_ACTION_ANGGOTA',
          payload: 'DELETE'
        })

        dispatch({
          type: 'SET_LOADING_ANGGOTA',
          payload: false
        })

        dispatch({
          type: 'SET_MESSAGE',
          payload: response.data.message
        })

        if (response.data.success) {
          dispatch({
            type: 'SET_PROCESSED_ANGGOTA',
            payload: response.data.data
          })
        }
      })
      .catch((error) => {
        fetch_error(error, dispatch)

        dispatch({
          type: 'SET_LOADING_ANGGOTA',
          payload: false
        })
      })
  }
}

export function doDeleteAnggotaAdmin(data) {
  return (dispatch, store) => {
    // console.log(data)

    // let id = store().anggota.current_data.id
    const id = data.no_kta

    data._method = 'POST'

    const options = {
      method: 'POST',
      headers: set_auth_header(store),
      url: process.env.REACT_APP_API_URL + '/admin_delete/' + id,
      data: secure_data(data)
    }

    dispatch({
      type: 'SET_VALIDATION',
      payload: []
    })

    dispatch({
      type: 'SET_LOADING_ANGGOTA',
      payload: true
    })

    dispatch({
      type: 'SET_LOADING_FILTER_WILAYAH',
      payload: true
    })

    axios(options)
      .then(function (response) {
        // console.log(response.data)

        dispatch({
          type: 'SET_ACTION_ANGGOTA',
          payload: 'DELETE'
        })

        dispatch({
          type: 'SET_LOADING_ANGGOTA',
          payload: false
        })

        dispatch({
          type: 'SET_LOADING_FILTER_WILAYAH',
          payload: false
        })

        dispatch({
          type: 'SET_MESSAGE',
          payload: response.data.message
        })

        if (response.data.success) {
          dispatch({
            type: 'SET_PROCESSED_ANGGOTA',
            payload: response.data.data
          })

          dispatch({
            type: 'SET_FORM_ANGGOTA',
            payload: false
          })
        }
      })
      .catch((error) => {
        fetch_error(error, dispatch)

        dispatch({
          type: 'SET_LOADING_ANGGOTA',
          payload: false
        })
      })
  }
}

export function doUpdateAnggota(data) {
  return (dispatch, store) => {
    // console.log(data)

    const id = data.id_anggota
    // let id = data.id;

    data._method = 'POST'

    const options = {
      method: 'POST',
      headers: set_auth_header(store),
      url: process.env.REACT_APP_API_URL + '/anggota/update/' + id,
      data: secure_data(data)
    }

    dispatch({
      type: 'SET_VALIDATION',
      payload: []
    })

    dispatch({
      type: 'SET_LOADING_ANGGOTA',
      payload: true
    })

    axios(options)
      .then(function (response) {
        // console.log(response.data)

        dispatch({
          type: 'SET_ACTION_ANGGOTA',
          payload: 'UPDATE'
        })

        dispatch({
          type: 'SET_LOADING_ANGGOTA',
          payload: false
        })

        if (response.data.success) {
          dispatch({
            type: 'SET_PROCESSED_ANGGOTA',
            payload: response.data.data
          })

          dispatch({
            type: 'SET_CURRENT_ANGGOTA',
            payload: response.data.data
          })

          dispatch({
            type: 'SET_ACTION_ANGGOTA',
            payload: 'UPDATE_SUCCESS'
          })

          dispatch({
            type: 'SET_FORM_ANGGOTA',
            payload: false
          })
        } else {
          dispatch({
            type: 'SET_MESSAGE',
            payload: response.data.message
          })
        }
      })
      .catch((error) => {
        fetch_error(error, dispatch)

        dispatch({
          type: 'SET_LOADING_ANGGOTA',
          payload: false
        })
      })
  }
}

export function doUpdatePendaftarAnggota(data) {
  return (dispatch, store) => {
    console.log('REDUX', data)

    const id = data.id_anggota
    // let id = data.id;

    data._method = 'POST'

    const options = {
      method: 'POST',
      headers: set_auth_header(store),
      url: process.env.REACT_APP_API_URL + '/pendaftar_anggota_update/' + id,
      data: secure_data(data)
    }

    dispatch({
      type: 'SET_VALIDATION',
      payload: []
    })

    dispatch({
      type: 'SET_LOADING_ANGGOTA',
      payload: true
    })

    axios(options)
      .then(function (response) {
        // console.log(response.data)

        dispatch({
          type: 'SET_ACTION_ANGGOTA',
          payload: 'UPDATE'
        })

        dispatch({
          type: 'SET_LOADING_ANGGOTA',
          payload: false
        })

        if (response.data.success) {
          dispatch({
            type: 'SET_PROCESSED_ANGGOTA',
            payload: response.data.data
          })

          dispatch({
            type: 'SET_CURRENT_ANGGOTA',
            payload: response.data.data
          })

          dispatch({
            type: 'SET_FORM_ANGGOTA',
            payload: false
          })
        } else {
          dispatch({
            type: 'SET_MESSAGE',
            payload: response.data.message
          })
        }
      })
      .catch((error) => {
        fetch_error(error, dispatch)

        dispatch({
          type: 'SET_LOADING_ANGGOTA',
          payload: false
        })
      })
  }
}

export function doUpdateAnggotaAdmin(data) {
  return (dispatch, store) => {
    // console.log(data)

    // let id = store().anggota.current_data.id
    const id = data.no_kta

    data._method = 'POST'

    const options = {
      method: 'POST',
      headers: set_auth_header(store),
      url: process.env.REACT_APP_API_URL + '/admin_index/' + id,
      data: secure_data(data)
    }

    dispatch({
      type: 'SET_VALIDATION',
      payload: []
    })

    dispatch({
      type: 'SET_LOADING_ANGGOTA',
      payload: true
    })

    axios(options)
      .then(function (response) {
        // console.log(response.data)

        dispatch({
          type: 'SET_ACTION_ANGGOTA',
          payload: 'UPDATE'
        })

        dispatch({
          type: 'SET_LOADING_ANGGOTA',
          payload: false
        })

        dispatch({
          type: 'SET_MESSAGE',
          payload: response.data.message
        })

        if (response.data.success) {
          dispatch({
            type: 'SET_PROCESSED_ANGGOTA',
            payload: response.data.data
          })

          dispatch({
            type: 'SET_FORM_ANGGOTA',
            payload: false
          })
        }
      })
      .catch((error) => {
        fetch_error(error, dispatch)

        dispatch({
          type: 'SET_LOADING_ANGGOTA',
          payload: false
        })
      })
  }
}

export function doCreateAnggota(data) {
  return (dispatch, store) => {
    // console.log(data)

    data._method = 'POST'

    const options = {
      method: 'POST',
      headers: set_auth_header(store),
      url: process.env.REACT_APP_API_URL + '/anggota/create',
      data: secure_data(data)
    }

    dispatch({
      type: 'SET_VALIDATION',
      payload: []
    })

    dispatch({
      type: 'SET_LOADING_ANGGOTA',
      payload: true
    })

    axios(options)
      .then(function (response) {
        // console.log(response.data)

        if (response.data.success) {
          dispatch({
            type: 'SET_PROCESSED_ANGGOTA',
            payload: response.data.data
          })

          dispatch({
            type: 'SET_CURRENT_ANGGOTA',
            payload: response.data.data
          })

          dispatch({
            type: 'SET_ACTION_ANGGOTA',
            payload: 'CREATE'
          })

          dispatch({
            type: 'SET_FORM_ANGGOTA',
            payload: false
          })
        } else {
          dispatch({
            type: 'SET_MESSAGE',
            payload: response.data.message
          })
        }

        dispatch({
          type: 'SET_LOADING_ANGGOTA',
          payload: false
        })
      })
      .catch((error) => {
        fetch_error(error, dispatch)

        dispatch({
          type: 'SET_LOADING_ANGGOTA',
          payload: false
        })
      })
  }
}

export function getAnggota(
  filter_provinsi = null,
  filter_kabupaten = null,
  filter_kecamatan = null,
  page = 0,
  load = 5,
  keyword = '',
  sorted = [],
  email = ''
) {
  return (dispatch, store) => {
    const url = process.env.REACT_APP_API_URL + '/anggota?'

    // if (id) {
    //   url += '&id=' + id
    // }

    // if (filter_provinsi) {
    //   url += '&filter_provinsi=' + filter_provinsi
    // }
    //
    // if (filter_kabupaten) {
    //   url += '&filter_kabupaten=' + filter_kabupaten
    // }
    //
    // if (filter_kecamatan) {
    //   url += '&filter_kecamatan=' + filter_kecamatan
    // }
    //
    // if (email) {
    //   url += '&email=' + email
    // }
    const options = {
      data: secure_data({
        filter_provinsi,
        filter_kabupaten,
        filter_kecamatan,
        page,
        load,
        keyword,
        sorted
      }),
      method: 'POST',
      headers: set_auth_header(store),
      url
    }

    /*
    dispatch({
    type: 'SET_VALIDATION',
    payload: []
  })
  */

    dispatch({
      type: 'SET_LOADING_ANGGOTA',
      payload: true
    })

    axios(options)
      .then(function (response) {
        // console.log(response.data)
        dispatch({
          type: 'SET_ACTION_ANGGOTA',
          payload: 'GET'
        })
        dispatch({
          type: 'SET_LOADING_ANGGOTA',
          payload: false
        })
        dispatch({
          type: 'SET_ANGGOTA',
          payload: response.data
        })
      })
      .catch((error) => {
        fetch_error(error, dispatch)
        dispatch({
          type: 'SET_LOADING_ANGGOTA',
          payload: false
        })
      })
  }
}

export function getAnggotaByMenu(
  filter_provinsi = null,
  filter_kabupaten = null,
  filter_kecamatan = null,
  page = 0,
  load = 5,
  keyword = '',
  sorted = [],
  menu = ''
) {
  return (dispatch, store) => {
    const url = process.env.REACT_APP_API_URL + '/anggota_by_menu'

    // if (id) {
    //   url += '&id=' + id
    // }

    // if (filter_provinsi) {
    //   url += '&filter_provinsi=' + filter_provinsi
    // }
    //
    // if (filter_kabupaten) {
    //   url += '&filter_kabupaten=' + filter_kabupaten
    // }
    //
    // if (filter_kecamatan) {
    //   url += '&filter_kecamatan=' + filter_kecamatan
    // }
    //
    // if (email) {
    //   url += '&email=' + email
    // }
    const options = {
      data: secure_data({
        filter_provinsi,
        filter_kabupaten,
        filter_kecamatan,
        page,
        load,
        keyword,
        sorted,
        menu
      }),
      method: 'POST',
      headers: set_auth_header(store),
      url
    }

    /*
    dispatch({
    type: 'SET_VALIDATION',
    payload: []
  })
  */

    dispatch({
      type: 'SET_LOADING_ANGGOTA',
      payload: true
    })

    axios(options)
      .then(function (response) {
        // console.log(response.data)
        dispatch({
          type: 'SET_ACTION_ANGGOTA',
          payload: 'GET'
        })
        dispatch({
          type: 'SET_LOADING_ANGGOTA',
          payload: false
        })
        dispatch({
          type: 'SET_ANGGOTA',
          payload: response.data
        })
      })
      .catch((error) => {
        fetch_error(error, dispatch)
        dispatch({
          type: 'SET_LOADING_ANGGOTA',
          payload: false
        })
      })
  }
}

export function getAnggotaById(usr) {
  return (dispatch, store) => {
    const url = process.env.REACT_APP_API_URL + '/anggota_by_id/' + usr

    // if (id) {
    //   url += '&id=' + id
    // }

    // if (usr) {
    //   url += '&id=' + usr
    // }

    try {
      const options = {
        data: secure_data({
          usr
        }),
        method: 'POST',
        headers: set_auth_header(store),
        url
      }

      dispatch({
        type: 'SET_LOADING_ANGGOTA_BY_ID',
        payload: true
      })

      axios(options)
        .then(function (response) {
          console.log(response.data)
          dispatch({
            type: 'SET_LOADING_ANGGOTA_BY_ID',
            payload: false
          })
          dispatch({
            type: 'SET_ANGGOTA_BY_ID',
            payload: response.data
          })
        })
        .catch((error) => {
          fetch_error(error, dispatch)
          dispatch({
            type: 'SET_LOADING_ANGGOTA_BY_ID',
            payload: false
          })
        })
    } catch (e) {}
  }
}

export function getAnggotaDashboard(usr) {
  return (dispatch, store) => {
    const url = process.env.REACT_APP_API_URL + '/anggota_dashboard/' + usr

    // if (id) {
    //   url += '&id=' + id
    // }

    // if (usr) {
    //   url += '&id=' + usr
    // }

    try {
      const options = {
        data: secure_data({
          usr
        }),
        method: 'POST',
        headers: set_auth_header(store),
        url
      }

      dispatch({
        type: 'SET_LOADING_ANGGOTA_DASHBOARD',
        payload: true
      })

      axios(options)
        .then(function (response) {
          console.log(response.data)
          dispatch({
            type: 'SET_ANGGOTA_DASHBOARD',
            payload: response.data
          })
          dispatch({
            type: 'SET_LOADING_ANGGOTA_DASHBOARD',
            payload: false
          })
        })
        .catch((error) => {
          fetch_error(error, dispatch)
          dispatch({
            type: 'SET_LOADING_ANGGOTA_DASHBOARD',
            payload: false
          })
        })
    } catch (e) {}
  }
}

export function getAnggotaByNoKtaCash(no_kta) {
  return (dispatch, store) => {
    const url =
      process.env.REACT_APP_API_URL + '/anggota_by_no_kta_cash/' + no_kta

    // if (id) {
    //   url += '&id=' + id
    // }

    // if (usr) {
    //   url += '&id=' + usr
    // }

    try {
      const options = {
        data: secure_data({
          no_kta
        }),
        method: 'POST',
        headers: set_auth_header(store),
        url
      }

      axios(options)
        .then(function (response) {
          // console.log(response.data)
          // dispatch({
          //   type: 'SET_LOADING_ANGGOTA',
          //   payload: false
          // })
          dispatch({
            type: 'SET_ANGGOTA_BY_NO_KTA_CASH',
            payload: response.data
          })
        })
        .catch((error) => {
          fetch_error(error, dispatch)
          // dispatch({
          //   type: 'SET_LOADING_ANGGOTA',
          //   payload: false
          // })
        })
    } catch (e) {}
  }
}

export function getAnggotaPembayaranByNoKta(no_kta_anggota) {
  return (dispatch, store) => {
    const url = process.env.REACT_APP_API_URL + '/anggota_pembayaran_by_no_kta/'

    // if (id) {
    //   url += '&id=' + id
    // }

    // if (usr) {
    //   url += '&id=' + usr
    // }

    try {
      const options = {
        data: secure_data({
          no_kta_anggota
        }),
        method: 'POST',
        headers: set_auth_header(store),
        url
      }

      axios(options)
        .then(function (response) {
          // console.log(response.data)
          // dispatch({
          //   type: 'SET_LOADING_ANGGOTA',
          //   payload: false
          // })
          dispatch({
            type: 'SET_ANGGOTA_BY_NO_KTA_CASH',
            payload: response.data
          })
        })
        .catch((error) => {
          fetch_error(error, dispatch)
          // dispatch({
          //   type: 'SET_LOADING_ANGGOTA',
          //   payload: false
          // })
        })
    } catch (e) {}
  }
}

export function getAnggotaByNoKta(no_kta) {
  return (dispatch, store) => {
    const url = process.env.REACT_APP_API_URL + '/anggota_by_no_kta/' + no_kta

    // if (id) {
    //   url += '&id=' + id
    // }

    // if (usr) {
    //   url += '&id=' + usr
    // }

    try {
      const options = {
        data: secure_data({
          no_kta
        }),
        method: 'POST',
        headers: set_auth_header(store),
        url
      }

      dispatch({
        type: 'SET_LOADING_ANGGOTA_BY_KTA',
        payload: true
      })

      axios(options)
        .then(function (response) {
          // console.log(response.data)
          dispatch({
            type: 'SET_LOADING_ANGGOTA_BY_KTA',
            payload: false
          })
          dispatch({
            type: 'SET_ANGGOTA_BY_NO_KTA',
            payload: response.data
          })
        })
        .catch((error) => {
          fetch_error(error, dispatch)
          dispatch({
            type: 'SET_LOADING_ANGGOTA_BY_KTA',
            payload: false
          })
        })
    } catch (e) {}
  }
}

export function getPendaftarAnggota(
  filter_provinsi = null,
  filter_kabupaten = null,
  filter_kecamatan = null,
  page = 0,
  load = 5,
  keyword = '',
  sorted = [],
  email = ''
) {
  return (dispatch, store) => {
    const url = process.env.REACT_APP_API_URL + '/pendaftar_anggota'

    // if (id) {
    //   url += '&id=' + id
    // }

    // if (usr) {
    //   url += '&id=' + usr
    // }

    try {
      const options = {
        data: secure_data({
          filter_provinsi,
          filter_kabupaten,
          filter_kecamatan,
          page,
          load,
          keyword,
          sorted
        }),
        method: 'POST',
        headers: set_auth_header(store),
        url
      }

      axios(options)
        .then(function (response) {
          // console.log(response.data)
          // dispatch({
          //   type: 'SET_LOADING_ANGGOTA',
          //   payload: false
          // })
          dispatch({
            type: 'SET_ANGGOTA',
            payload: response.data
          })
        })
        .catch((error) => {
          fetch_error(error, dispatch)
          // dispatch({
          //   type: 'SET_LOADING_ANGGOTA',
          //   payload: false
          // })
        })
    } catch (e) {}
  }
}

export default function anggota(
  state = {
    total: 0,
    response: [],
    loading: false,
    loading_by_kta: false,
    loading_anggota_dashboard: false,
    loading_anggota_by_id: false,
    current_data: {},
    form: null,
    processed_data: null,
    action: null,
    date: new Date(),
    data_dashboard: [],
    data_by_id: [],
    data_by_no_kta: []
  },
  action
) {
  switch (action.type) {
    case 'SET_ANGGOTA':
      return update(state, {
        response: {
          $set: action.payload
        },
        processed_data: {
          $set: null
        }
      })

    case 'SET_ANGGOTA_BY_ID':
      return update(state, {
        data_by_id: {
          $set: action.payload
        },
        processed_data: {
          $set: null
        }
      })

    case 'SET_ANGGOTA_DASHBOARD':
      return update(state, {
        data_dashboard: {
          $set: action.payload
        },
        processed_data: {
          $set: null
        }
      })

    case 'SET_ANGGOTA_BY_NO_KTA':
      return update(state, {
        data_by_no_kta: {
          $set: action.payload
        },
        processed_data: {
          $set: null
        }
      })

    case 'SET_ANGGOTA_BY_NO_KTA_CASH':
      return update(state, {
        data_by_no_kta_cash: {
          $set: action.payload
        },
        processed_data: {
          $set: null
        }
      })

    case 'RESET_ANGGOTA':
      return update(state, {
        current_data: {
          $set: null
        },
        processed_data: {
          $set: null
        }
      })

    case 'SET_CURRENT_ANGGOTA':
      return update(state, {
        current_data: {
          $set: action.payload
        }
      })

    case 'SET_PROCESSED_ANGGOTA':
      return update(state, {
        processed_data: {
          $set: action.payload
        }
      })

    case 'SET_LOADING_ANGGOTA_BY_KTA':
      return update(state, {
        loading_by_kta: {
          $set: action.payload
        }
      })

    case 'SET_LOADING_ANGGOTA':
      return update(state, {
        loading: {
          $set: action.payload
        }
      })

    case 'SET_ACTION_ANGGOTA':
      return update(state, {
        action: {
          $set: action.payload
        }
      })

    case 'SET_LOADING_ANGGOTA_BY_ID':
      return update(state, {
        loading_anggota_by_id: {
          $set: action.payload
        }
      })

    case 'SET_LOADING_ANGGOTA_DASHBOARD':
      return update(state, {
        loading_anggota_dashboard: {
          $set: action.payload
        }
      })

    case 'SET_TOTAL_ANGGOTA':
      return update(state, {
        total: {
          $set: action.payload
        }
      })

    case 'SET_FORM_ANGGOTA':
      return update(state, {
        form: {
          $set: action.payload
        }
      })

    // // case 'FIND_ANGGOTA':
    //   // let find = _.find(state.response, function (o) {
    //     // return o.id < action.payload
    //   })
    //
    //   return update(state, {
    //     current_data: {
    //       $set: find
    //     }
    //   })

    default:
      return state
  }
}
